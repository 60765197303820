.thumbs-radio {
  display: inline-block;

  input[type="radio"] {
    display: none;

    &:not(:disabled) ~ label {
      cursor: pointer;
    }
  }

  label {
    padding: 1rem;
    display: block;
    margin: 0 0.6rem;
    &.up,
    &.down {
      background-size: 33px;
      background-image: url(../../assets/images/thumbs_nofill.svg);
      background-repeat: no-repeat;
    }
    &.down {
      transform: rotate(180deg);
    }
  }

  input[type="radio"]:checked + label {
    &.up {
      background-image: url(../../assets/images/thumbs_up.svg);
    }

    &.down {
      transform: rotate(360deg);
      background-image: url(../../assets/images/thumbs_down.svg);
    }
  }
}

.assessment-info {
  font-family: "Manrope";
  background-color: #eff0f6;

  .author-back-btn:hover {
    background-color: #eff0f6 !important;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  h1 {
    font-size: 24px;
    font-weight: 700;
    @extend .mb-8;

    @media screen and (max-width: 991px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  p {
    @extend .mb-8;
    font-weight: 400;
    font-size: 14px;
    color: var(--text-secondary);
  }
  .price {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    /* identical to box height, or 92% */
    color: #ff9933;
  }
}
.assessment-info-card {
  font-family: "Manrope";
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none !important;
  height: 100% !important;

  h1 {
    font-size: 24px;
    font-weight: 700;
    @extend .mb-8;

    @media screen and (max-width: 991px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  p {
    @extend .mb-8;
    font-weight: 400;
    font-size: 14px;
    color: var(--text-secondary);
  }

  .profile-img-card {
    background-color: #eff0f6;
  }
  .totalUSer {
    h1 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      color: #b52dc8;
    }
  }
  .totalEarnings {
    h1 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      color: #6182e7;
    }
  }
  .totalCompletion {
    h1 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      color: #be5dfa;
    }
  }
  .totalRatings {
    h1 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      color: #687ae3;
    }
  }
}

.signup,
.signin,
.otp,
.verify-otp,
.forget-password {
  .main-container {
    @media screen and (max-width: 767px) {
      margin-top: 0 !important;
    }
  }
  .signin-top {
    @media screen and (max-width: 767px) {
      padding: 1rem;
    }
  }
  input:focus ~ label {
    color: var(--primary);
  }
}

.otp {
  .main-container {
    @media screen and (max-width: 767px) {
      padding: 2rem !important;
    }
  }
}

.verify-otp,
.forget-password {
  margin: 0;
  padding: 0;
  .main-container {
    padding: 0 2rem;
  }
}

.notes-wrapper {
  width: 98%;
  border: 1px solid var(--line-gray);
  padding: 0;
  border-radius: 4px;
  margin-left: 10px;
  .notes-editor {
    &.editor-focus {
      background: var(--white);
      border: 1px solid var(--primary);
    }
    background: var(--background-secondary);
    border: none !important;
    padding: 14px 12px;
    height: 150px;
  }

  .rdw-link-modal {
    height: auto !important;
  }

  .rdw-editor-toolbar {
    background: var(--background-secondary);
    border-bottom: 1px solid var(--line-gray) !important;
    border: none;
    margin: 0;
  }

  .rdw-option-wrapper {
    padding: 10px 5px;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid transparent;

    &:hover {
      box-shadow: none;
      border: 1px solid transparent;
    }

    &.rdw-option-active {
      box-shadow: none;
      background-color: var(--white);
    }
  }

  .rdw-dropdown-wrapper {
    background-color: transparent;
    border: none;
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }

    .rdw-dropdown-selectedtext {
      color: var(--text-secondary);
      font-weight: 600;
      text-decoration: none;
    }
  }

  .rdw-custom-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
  }
}

.uploaded-img {
  width: 100%;
  height: 210px;
  border: 1px solid #cccddd;
  border-radius: 2.5px;
  background-color: #eff0f6;
}

.selected-img,
.selected-video {
  margin-top: 12vh;
}

.u-image-name,
.u-image-btn,
.u-video-name,
.u-video-btn {
  border: 1px solid #cccddd;
}

.u-image-name,
.u-video-name {
  width: 200px;
  font-size: 16px;
  font-weight: 400;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.u-image-btn,
.u-video-btn,
.u-image-btn button {
  background-color: #5b2aef;
  color: #ffff;
  font-size: 16px;
  font-weight: 400;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.u-video-name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.next-btn {
  height: 50px;
  width: 135px;
}

.sub-text {
  color: #6a6f73;
}

.form-title {
  color: #1c1d1f;
}

// intended learners page

.intend-title-div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.valid-till:focus {
  box-shadow: none !important;
}

.selected-video-div {
  height: 100%;
  width: 100%;
}
.college-list-container {
  .filter-list-col.col-sm-3 > * {
    float: left;

    & > div {
      float: left;
      margin: 2px;
    }
  }

  .filter-list-col.col-sm-12 > * {
    float: left;

    & > div {
      float: left;
      margin: 2px;
    }
  }

  .filter-list-col.col-sm-12 {
    padding-left: 0;
    margin-bottom: 5px;
  }

  .accordion-button:not(.collapsed) {
    // background-color: #eff0f6;
    background-color: transparent;
    // border: 0 !important;
    // padding: 0;
    box-shadow: none !important;
    padding-right: 0;
    color: #353535 !important;
  }

  .accordion-item:first-of-type .accordion-button {
    padding-top: 0;
  }

  .accordion-item .accordion-button {
    padding-right: 0;
  }

  .accordion-item:last-of-type {
    margin-top: 21px !important;
  }

  .accordion-item {
    border: 0 !important;
  }

  .accordion-body {
    padding: 0;

    & > .card {
      .card-body {
        padding-right: 0;
      }
    }

    .input-group + .card {
      height: 34.5vh;
      overflow-y: auto;

      .card-body {
        padding: 5px;
      }
    }

    .mb-3 {
      margin-bottom: 5px !important;
    }

    .form-check {
      font-family: "Manrope";
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;

      label {
        font-size: 12px;
        width: 100%;
      }
    }

    .input-group {
      // padding-bottom: 10px;
      font-family: "Manrope";
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;

      img {
        height: 10.66670036315918px;
        width: 10.66670036315918px;
      }

      input {
        font-family: "Manrope";
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .card {
      border: 0 !important;
    }
  }

  .accordion-button:focus {
    border-color: transparent;
    box-shadow: none;
  }

  .accordion-button {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 0px;
  }

  .container-fluid {
    padding: 10px;
  }

  .list-group-numbered > .list-group-item::before {
    content: "" !important;
  }

  .list-header {
    display: flex;
    padding-bottom: 10px;
  }

  .list-header-text {
    padding-left: 10px;

    & > div.fw-bold {
      font-family: "Manrope";
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #4a4a4a;
    }

    .fw-light {
      font-weight: 300 !important;
      font-family: "Manrope";
      font-size: 12px;
      //   font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #8a8a8a;
    }
  }

  .list {
    padding-top: 10px;
    /* border-top: 1px solid #ccc; */
    width: 100%;

    .rounded-circle {
      // border-radius: 50%!important;
      // width: 30px !important;
      // height: 30px !important;
      height: 36px !important;
      width: 34.609375px !important;
      left: 3.703125px !important;
      top: 3px !important;
      border-radius: 9px !important;
    }
  }

  .list-content {
    padding-top: 10px;
    border-top: 1px solid #ccc;

    .card {
      border: 0 !important;

      .card-body {
        padding: 0;
      }
    }
  }

  .content-text-group .list-col {
    padding: 5px;
  }
  .content-text-group {
    .course-offered-text {
      color: #9246c8;
    }
    .course-offered-tilte,
    .col-group-1 {
      color: #8a8a8a;
    }
  }

  .list-group-item {
    border-radius: 5px !important;
    margin: 5px 0;
  }

  .list-group-item:first-child {
    margin-top: 0;
  }

  .list-group-item + .list-group-item {
    border-top-width: thin;
  }

  .col-group-1 {
    font-family: "Manrope";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }

  .col-group-2 {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #4a4a4a;
  }

  .filters-header {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    .col-sm-3 {
      padding-left: 30px;

      & > div {
        cursor: pointer;
        float: left;
        width: 100%;

        & > div {
          float: right;
          color: #9246c8;
        }
      }
    }
  }

  //   .filters-list-block {
  //       margin-top: 15px;
  //   }

  .filters-header {
    img {
      margin-right: 5px;
    }
  }

  //   .filterTile {
  //       padding-left: 30px;
  //   }

  .filter-style-div {
    background-color: #f4f4f4;
    border: 1px solid #2f1238;
    border-radius: 40px;
    padding: 5px 8px;
    flex: 0 0 auto;
    max-width: max-content;
    /* height: 32px; */
    /* padding-left: 6px; */
    /* padding-top: 2px; */
    display: inline-flex;
    font-size: 12px;
  }

  .crossmark {
    flex-grow: 1;
    display: flex;
    top: unset;
    /* right: unset; */
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-left: 4px;
    cursor: pointer;
    background-image: url(https://images.shiksha.ws/pwa/public/images/ctpV2/x.svg);
  }

  /*
*  STYLE 1
*/

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #cccddd;
  }

  ._2e54 {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: var(--s6);
    gap: var(--s3);
    max-height: 130px;
    overflow-y: scroll;
  }

  span.eafb {
    flex-grow: 1;
    flex-shrink: 0;
    transform: rotate(0deg);
    display: inline-block;
    top: unset;
    right: unset;
    width: 14px;
    height: 14px;
  }

  .chip__close {
    transform: rotate(45deg);
    position: relative;
    display: inline-block;
    top: 2px;
    right: -9px;
    width: 15px;
    height: 15px;
  }
}

.college-list-search {
  margin-left: 2.5% !important;
}

.search-field-list {
  justify-content: end;
  margin-bottom: 1rem;
}

.img-college {
  background-image: url("../../../assets/images/collegeSidebar.svg");
  background-repeat: no-repeat;
}

.college-button {
  margin-top: 21rem;
  // justify-content: center;
  // text-align: center;
  margin-left: 3.7rem;
}

.link-colleges > a {
  text-decoration: none;
}

.dataResult {
  text-decoration: none;
  //   height:100px;
  margin-left: 3px !important;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  //   overflow-y: auto;
  //   overflow-x: auto;
  border-bottom: 0.5px solid #ebecf2;
  padding: 2px;
  position: absolute;
  // //   top: 16%;
  z-index: 100;
  width: 35% !important;
  border-radius: 5px;
}

.optVal {
  font-weight: 500;
  border-bottom: 0.5px solid #ebecf2;
  margin-left: 2px;
  padding: 5px;
}

.optVal:hover {
  background-color: #e8ebff;
  cursor: pointer;
}

.sideBar {
  border: 1px solid #cccddd;
  padding: 10px;
  border-radius: 5px;
}

.filters-list-block,
.img-college {
  position: sticky;
  top: 0;
  height: 110vh;
  // width: 25vw;
  // background-color: #f0f0f0;
  // padding: 20px;
}

.framework-desc {
  font-weight: 800;
  font-size: 36px;
  text-align: center;
  color: #8c009e;
  line-height: 120%;
  font-family: "Manrope";
}

.complete-assessment {
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  font-family: "Manrope";
}

.complete-assessment1 {
  color: #000000;
  font-weight: 500;
  font-size: 15px;
  font-family: "Manrope";
  text-align: justify;
}

.personality-head {
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  color: #2749a0;
  line-height: 120%;
  font-family: "Manrope";
}

.input-card {
  border: 1px solid #6c6ed2;
  background-color: #6c6ed2;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  font-family: "Manrope";
  background-image: url("../../assets/images/twoArrow.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

.strength-card {
  border: 1px solid #259fac;
  background-color: #259fac;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  font-family: "Manrope";
  background-image: url("../../assets/images/twoArrow.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

.value-card {
  border: 1px solid #712e57;
  background-color: #712e57;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  font-family: "Manrope";
  background-image: url("../../assets/images/twoArrow.svg");
  background-repeat: no-repeat;
  background-position: right center;
  padding: 10px 70px 10px 10px;
}

.heading-content {
  color: #000000;
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
  font-family: "Manrope";
  margin-left: 15px;
}

.progress_div {
  width: 100%;
}

.ig-assessment.progress {
  border-radius: 0px !important;
  font-family: Roboto;
}

.purple_progress .progress-bar {
  background-color: #ad35cb !important;
}

.purple_progress {
  background-color: #e0e0e0 !important;
}

.orange_progress .progress-bar {
  background-color: #e88956 !important;
}

.orange_progress {
  background-color: #e0e0e0 !important;
}

.blue_progress .progress-bar {
  background-color: #2749a0 !important;
}

.blue_progress {
  background-color: #e0e0e0 !important;
}

.progress_text {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  text-align: start !important;
}

.blue_text {
  color: #2963e9 !important;
  font-weight: 700;
}

.pink_text {
  color: #8c009e !important;
  font-weight: 700;
}

.orange_text {
  color: #e88956 !important;
  font-weight: 700;
}

.chiffon_text {
  color: #259fac !important;
  font-weight: 700;
}

.percentage_text {
  font-size: 14px;
  font-weight: 400;
}

.disclaimer-head {
  color: #4456dc;
  font-weight: 700;
  font-size: 22px;
  font-family: "Manrope";
  margin-left: 15px;
  text-align: center;
}

.interest-head {
  text-align: center;
  color: #ad35cb;
  font-weight: 800;
  font-size: 30px;
  line-height: 120%;
  font-family: "Manrope";
}

.num-list {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  font-family: "Manrope";
  text-align: justify;
}

.intreset-code {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  font-family: "Manrope";
  text-align: justify;
}

.apti-head {
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  color: #e88956;
  line-height: 120%;
  font-family: "Manrope";
}

.character-head {
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  color: #259fac;
  line-height: 120%;
  font-family: "Manrope";
}

.openques-head {
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  color: #057773;
  line-height: 120%;
  font-family: "Manrope";
}

.topstream-head {
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  color: #b83463;
  line-height: 120%;
  font-family: "Manrope";
}

.career-head {
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  color: #975252;
  line-height: 120%;
  font-family: "Manrope";
}

// pages css
.fs-20 {
  font-size: 20px;
}

.fw-800 {
  font-weight: 800;
}

.purpel_text {
  color: rgba(140, 0, 158, 1);
}

.a_page {
  height: 100vh;
  background-image: url("../../assets/images/page1_bg.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.page_1,
.page_2,
.page_4,
.page_30 {
  font-family: inter;
}

.ellipse_1 {
  transform: translate(700px, -130px);
}

.ellipse_2 {
  transform: translate(-300px, 0px);
}

.ellipse_3 {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.blue_text {
  color: rgba(42, 98, 232, 1);
  font-family: "Manrope";
}

.page_2,
.page_30 {
  height: 100vh;
}

.page_2_bg_1 {
  height: 250px;
  background-image: url("../../assets/images/world.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.page_2_bg_2 {
  height: 60%;
  width: 100%;
  background-image: url("../../assets/images/stripes.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  transform: translateY(-50px);
}

.page_2_bg_2 div {
  width: 6.48%;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  margin: 1.5% 3.8%;
  font-family: "Manrope";
}

.pyramid {
  position: absolute;
  bottom: 0px;
  width: 70%;
  left: 12%;
}

.location {
  position: absolute;
  left: 38%;
  transform: translateY(100px);
}

.page_2_btn_div {
  transform: translateY(-150px);
}

.page_30 {
  background-image: url("../../assets/images/banner.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 2%;
}

.periodic_table {
  font-family: inter;
}

.periodic_table .row {
  margin: 0 !important;
  padding: 0 !important;
}

.periodic_table .col {
  margin: 0 !important;
  padding: 0 !important;
}

.table_chip {
  font-weight: 500;
  text-align: center;
  padding: 10px 20px;
  background-color: rgba(217, 217, 217, 1);
  text-transform: uppercase;
  border-radius: 8px;
  margin: 8px 0px;
  font-size: 12px;
  font-family: "Manrope";
  display: inline-block;
  width: 120px;
  box-sizing: border-box;
  overflow: hidden;
  word-wrap: initial;
}

.maroon_chip {
  background-color: #761631;
  color: #ffffff;
}

.purple_chip {
  background-color: rgba(57, 5, 100, 1);
  color: #ffffff;
}

.cyan_chip {
  background-color: rgba(34, 80, 91, 1);
  color: #ffffff;
}

.blue_chip {
  background-color: rgba(27, 24, 86, 1);
  color: #ffff;
}

.amber_chip {
  background-color: rgba(143, 83, 29, 1);
  color: #ffff;
}

.dark_blue_chip {
  background-color: rgba(6, 24, 57, 1);
  color: #ffffff;
}

.steps-head {
  text-align: start;
  color: #000000;
  font-weight: 800;
  font-size: 30px;
  line-height: 120%;
  font-family: "Manrope";
}

.steps-method {
  font-weight: 600;
  font-size: 18px;
  line-height: 124%;
  color: #ed1313;
}

.steps-desc {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 124%;
  color: #ed1313;
}

.session-btn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.score-heading {
  color: #a8a8a8;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  font-family: "Manrope";
  margin-left: 15px;
}

.image-vw {
  text-align: center;
}

.assess-summary {
  color: #2963e9;
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  line-height: 120%;
  font-family: "Manrope";
}

.summaryAssess-bg {
  background-color: #f5f5f5;
}

.summary-heading {
  text-align: center;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  font-family: "Manrope";
}

.periodic-table {
  font-weight: 800;
  font-size: 25px;
  color: #259fac;
  line-height: 120%;
  font-family: "Manrope";
}

.assess-wh {
  align-items: center !important;
  justify-items: center !important;
  justify-content: center !important ;
}

.session-btn {
  display: flex !important;
  justify-content: center !important;
}

.image-card {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  font-family: "Manrope";
  align-items: center;
  margin-top: 5px;
}

.image_container {
  position: relative;
}

.content_container {
  position: absolute;
  bottom: 150px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
}

.session-schedule {
  background-color: #c954e7 !important;
  font-family: "Manrope", sans-serif !important;
}

svg.speedometer {
  height: 200px !important;
}

.text-white {
  font-family: "Manrope";
  align-items: center;
  text-align: center;
}

.desc-text {
  font-family: "Manrope";
}

.top-streams {
  opacity: 0.5;
  filter: blur(4px);
}

.lock-img {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  margin: auto;
}

.maroon_chip {
  background-color: rgba(118, 22, 49, 1);
  color: #ffffff;
}

.assess-wh {
  align-items: center !important;
  justify-items: center !important;
  justify-content: center !important ;
}
.session-btn {
  display: flex !important;
  justify-content: center !important;
}

.image-card {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  font-family: "Manrope";
  align-items: center;
  margin-top: 5px;
}

.c_progress {
  border-radius: 0 !important;
  height: 20px !important;
}

.book_session_btn {
  background-color: #c954e7 !important;
  border-color: #c954e7 !important;
  box-shadow: 0px 2px #4a0090 !important;
  padding: 10px 30px !important;
  font-family: "Manrope", sans-serif !important;
}

.current-value {
  font-weight: 500 !important;
  font-size: 20px !important;
  fill: rgba(232, 137, 86, 1) !important;
}

.steps-desc,
.steps-method {
  color: #000000 !important;
}

.footer_info {
  text-align: start !important;
}

.footer_info a {
  text-decoration: none;
  color: #ffff;
}

.page_container {
  padding: 0px 130px;
}

.main_bg {
  background-image: url("../../assets/images/main_bg.svg");
}

.blue_bg {
  background-image: url("../../assets/images/blue_bg.svg");
}

.sky_bg {
  background-image: url("../../assets/images/sky_bg.svg");
}

.maroon_bg {
  background-image: url("../../assets/images/maroon_bg.svg");
}

.orange_bg {
  background-image: url("../../assets/images/orange_bg.svg");
}

.white_bg {
  background-color: #ffff;
  min-height: 850px;
  font-family: "Manrope";
}

.apti_img .image-card-container {
  text-align: center;
  padding-left: 30%;
}

.apti_img_2 .image-card-container {
  text-align: center;
  padding-right: 30%;
}

.input-line {
  width: 100%;
  border: 2px solid #dddada;
  margin: 35px 0;
}

.session-button > a {
  text-decoration: none !important;
}

.back-button {
  position: fixed;
  top: 25px;
  right: 3vw;
}

.speed_text {
  font-weight: 500;
  font-size: 20px;
  color: rgb(232, 137, 86);
}

.steps_list {
  list-style-type: none;
}

.image-card-container img {
  border-radius: 7.5px;
}

.input-card-text {
  text-align: justify !important;
}

.your-score {
  text-align: center;
  color: #8c009e;
  font-weight: 700;
}

.char-text,
.values-text {
  font-weight: 700;
}

.img-container {
  position: relative;
}

.icon-container {
  position: absolute;
  top: 0;
  right: 0;
}

.publisherContainer p {
  padding: 0;
  margin: 0;
}

.three-dots {
  color: var(--white);
  font-size: 30px;
}

.status-button-myassessment .btn {
  border-radius: 0;
  min-width: 90px;
  min-height: 45px;
  border: 1px solid #e8eaf2;
}

.status-button-myassessment .btn-primary {
  border-bottom: 1px solid #e8eaf2 !important;
}

.title p {
  font-weight: bold;
}

.title p:nth-child(2) {
  color: var(--warning-badges);
}

.author {
  font-size: 12px;
}

.rating p {
  font-size: 14px;
}

.img-container .dropdown {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999; 

}

.dropdown .dropdown-toggle::after {
  display: none;
}

.img-container .dropdown .btn {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  transform: rotate(90deg);
}

.img-container .dropdown .btn:hover {
  background-color: transparent;
}

.img-container .dropdown .btn-success {
  --bs-btn-active-bg: transparent;
}

.assessProgress {
  height: 5px !important;
  color: var(--bs-progress-bar-color) !important;
  --bs-progress-bar-bg: #5b2aef !important;
}
.assessmentActive {
  font-weight: 500;
  color: white;
  border: 1px solid #6610f2;
  border-radius: 4px;
  background-color: #6610f2;
}

.author span {
  font-size: 14px;
  color: #6c757d;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 25px;
}
.user-table-transcript .ag-row {
  display: flex;
  align-items: center; 
}

.user-table-transcript .ag-cell {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-items: center;
  white-space: normal; 
  padding-top: 28px; 
  line-height: 1.7; 
  overflow: hidden;
  text-overflow: ellipsis; 
}



* {
  font-family: "Manrope";
  font-style: normal;
}

.content {
  justify-content: center;
}

.headerCol {
  background-color: #f9f9f9;
}

.inst-name {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  padding: 10px;
}

.front-button {
  margin-left: 5px !important;
}

.table {
  // width: 793px !important;
  // height: 788px;
  background: #fbfdff;
  border: 1px solid #cccddd;
  margin-top: 10px !important;
  // margin-left: 2rem;
  align-items: flex-end;
  // justify-content: end;
  // width: ;
}

.table tr:first-child th {
  background: #5b2aef none repeat scroll 0 0;
  color: #fff;
}

.cont {
  background: #ffffff;
  margin-left: 3rem;
}

.fRow {
  background: #eff0f6;
  padding: 5px;
  align-items: center;
  border-bottom: 0.5px solid #d2d2d2;
  justify-content: flex-start;
}

.srow {
  border-bottom: 0.5px solid #d2d2d2;
}

.trow {
  border-bottom: 0.5px solid #d2d2d2;
}

.courseName {
  color: #5b2aef;
  font-weight: 700;
}

.card-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin: 0;
}

.courseCount {
  font-weight: 500;
}

.fees {
  font-weight: 500;
}

.duration {
  font-weight: 600;
}

.c985 {
  font-weight: 600;
}

.eligibility {
  font-weight: 600;
}
.exams {
  color: #5b2aef;
  font-weight: 700;
}

.heading {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}

.subTxt {
  font-weight: 500;
  font-size: 16px;
  color: #807878;
}

.cardProg {
  border-radius: 2px !important;
  margin-top: 10px;
  margin-left: 6px;
  width: 100% !important;
}

.program-heading {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}

.years {
  font-weight: 400;
  font-size: 13.44px;
  color: #7875aa;
  margin-top: 1rem;
}

.blockLabel {
  font-weight: 700;
  font-size: 12px;
  color: #666666;
}

.valueTxt {
  font-weight: 700;
  font-size: 15px;
  color: #5b2aef;
}

.paginationBttns {
  width: 80%;
  height: 40px;
  justify-content: center;
  list-style: none;
  display: flex;
  text-decoration: none;
  align-items: center;
}

.paginationBttns a {
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  color: #2b2eff;
  cursor: pointer;
}

.paginationActive {
  font-weight: 900;
  color: black;
}

.previousBttn {
  text-decoration: none;
  font-size: 2rem;
  color: #000000;
}

.nextBttn {
  text-decoration: none;
  font-size: 2rem;
  color: #000000;
}

.request-progress-container {
  position: relative;
  width: 68%;

  .progress {
    height: 6px;
    margin-top: 11px;
    border-radius: 8px;

    .bg-warning {
      background-color: var(--warning-badges) !important;
    }
  }

  span {
    position: absolute;
    font-style: normal;
    font-weight: 400;
    top: -0.4rem;
    right: -2rem;
    font-size: 12px;
    line-height: 16px;
  }
}

.ig-text-question {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;

  span {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    color: #000000;
  }
}

.test-answer-block {
  background: #ffffff;
  border: 2px solid #e8eaf2;
  border-radius: 4px;
}

input.text-question-answer {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #e8eaf2;
  background: none !important;
}

input.text-question-answer:focus {
  outline: 0;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 2px solid #5b2aef !important;
}

.test_radio_option_row {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height, or 120% */
  margin: 8px 0;
  letter-spacing: -0.2px;
  color: #1c1d1f;
}

.test_radio_option_row .form-check-input:checked[type="radio"] {
  background-image: none;
}

.ig-multiweb-header-color {
  color: #5b2aef;
}

.session-card {
  font-family: "Manrope";
  .date {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: var(--text-secondary);
  }
  
  .sessions {
    align-items: center;
    .time {
      h3 {
        font-style: normal;
        font-weight: 700;
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 26px;
        color: var(--text-default);
      }

      p {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--text-secondary);
      }
    }

    .time.grey h3,
    .time.grey p {
      color: var(--text-secondary) !important;
    }
  }
}
.question-input {
  flex: 4;
}

.img-container {
  text-align: center;
}

.dropdown-container {
  flex: 4;
}

.date-time-container,
.question-input {
  border-bottom: 2px solid #bfbfbf;
}

.answer-input {
  border-bottom: 2px dashed #d7d7d7 !important;
}

.answer-input .form-label,
.date-time-container .form-label,
.question-input .form-label {
  display: none;
}

.custom_input {
  padding-left: 0px !important;
}

.custom_input:focus {
  border: none !important;
  background: var(--white) !important;
  box-shadow: none !important;
  border-bottom: 2px solid #bfbfbf !important;
}

.custom_input:valid {
  border: none !important;
}

.disabled-div {
  pointer-events: none;
  opacity: 0.6;
}

.img-btn:hover {
  opacity: 0.6;
}

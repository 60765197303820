/* new page */
.menu-block.sidebar-menu {
  height: 74vh;
  .sidebar-block {
    display: flex;
  }
  .viewAllLbl {
    color: #5b2aef;
    font-weight: bold;
    /* align-items: center; */
    float: center;
    /* float: left; */
    padding-left: 12px;
    cursor: pointer;
  }
  .left-sidebar {
    width: 25%;
    background-color: #fff;
    /* padding: 5px; */
  }
  .left-sidebar li:hover {
    background-color: #f4f8fb;
  }
  .left-sidebar ul {
    margin: 0px;
    padding: 0px;
  }
  .menu-block {
    width: 60%;
    background-color: #eee;
    padding: 5px;
  }
  .sidebar-block {
    display: flex;
    width: 100%;
  }
  .right-sidebar-content {
    float: left;
    width: 75%;
    background-color: #f4f8fb;
  }
  .right-sidebar-content > * {
    float: left;
    width: 30%;
  }
  .sidebar-block li {
    list-style: none;
  }

  .sidebar-block li {
    font-weight: 600;
  }
  .sidebar-block li a {
    font-weight: 400;
  }
  .left-sidebar li {
    padding: 10px;
    /* font-weight: 600; */
    color: #222 !important;
    font-size: 14px !important;
    font-weight: 400;
    text-decoration: none;
  }
  .left-sidebar li::after {
    /* content: ">"; */
    float: right;
  }

  .sidebar-block li {
    cursor: pointer;
    color: #222 !important;
    font-size: 14px !important;
    font-weight: 400;
    padding: 10px;
    text-decoration: none;
  }
  .right-sidebar-content li {
    padding: 10px;
  }
  .right-sidebar-content li:not(:first-child) {
    padding-left: 12px !important;
  }
  .right-sidebar-content li:first-child {
    font-weight: 600 !important;
  }
  li.list-item.highlight {
    background-color: #f4f8fb;
    border-right: 3px solid #5b2aef;
    font-weight: 600;
  }
  li.list-item.highlight a {
    font-weight: 600;
  }
}

/* new page */
.sidebar-block {
  display: flex;
}
.left-sidebar {
  width: 25%;
  background-color: #fff;
  /* padding: 5px; */
}
.left-sidebar li:hover {
  background-color: #f4f8fb;
}
.left-sidebar ul {
  margin: 0px;
  padding: 0px;
}
.menu-block {
  width: 60%;
  background-color: #eee;
  padding: 5px;
}
.sidebar-block {
  display: flex;
  width: 100%;
}
.right-sidebar-content {
  float: left;
  width: 75%;
  background-color: #f4f8fb;
}
.right-sidebar-content > * {
  float: left;
  width: 30%;
}
.sidebar-block li {
  list-style: none;
}

.sidebar-block li {
  font-weight: 600;
}
.sidebar-block li a {
  font-weight: 400;
}
.left-sidebar li {
  padding: 10px;
  /* font-weight: 600; */
  color: #222 !important;
  font-size: 14px !important;
  font-weight: 400;
  text-decoration: none;
}
.left-sidebar li::after {
  /* content: ">"; */
  float: right;
}

.sidebar-block li {
  cursor: pointer;
  color: #222 !important;
  font-size: 14px !important;
  font-weight: 400;
  padding: 10px;
  text-decoration: none;
}
.right-sidebar-content li {
  padding: 10px;
}
.right-sidebar-content li:not(:first-child) {
  padding-left: 12px !important;
}
.right-sidebar-content li:first-child {
  font-weight: 600 !important;
}
li.list-item.highlight {
  background-color: #f4f8fb;
  border-right: 3px solid #5b2aef;
  font-weight: 600;
}
li.list-item.highlight a {
  font-weight: 600;
}

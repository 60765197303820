.card {
  &.no-borders {
    border: 0;
    .list-group-item,
    .card-header {
      border: 0;
    }
  }
  font-family: "Manrope";
  .card-header,
  .card-footer {
    background-color: var(--background-white);
  }
  .card-header {
    padding: 1rem;
    font-weight: 700;
    font-size: 18px;
  }
  .card-footer {
    text-align: center;
    a {
      color: var(--primary-dark);
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
    }
  }
  .date {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: var(--text-secondary);
    padding-right: var(--bs-list-group-item-padding-x);
    padding-left: var(--bs-list-group-item-padding-x);
  }
}

.workExpAlert {
  background: #5b2aef !important;
  border-radius: 8px;
  color: #ffffff !important;
}

.mentorAddBtn {
  color: #4a0090 !important;
  border-color: var(--line-gray) !important;
}

.mentorAddBtn:hover {
  background-color: transparent !important;
  text-decoration: none;
}

.mentorDeleteBtn {
  border: none !important;
}

.formLabel {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #858ab4;
}

.formLabelNote {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #858ab4;
}
.formControlField {
  font-family: "Manrope";
  background: #eff0f7 !important;
  border: 1px solid #cccddd !important;
  border-radius: 4px !important;
}

.errorMsg {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ff0000 !important;
}

.selectStyle {
  font-family: "Manrope";
  background: #eff0f7 !important;
  border: 1px solid #cccddd !important;
  border-radius: 4px !important;
}

.formLabelBold {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.Add-btn {
  margin-right: 5px;
  margin-bottom: 3px;
  text-decoration: none;
}

.mentor-card > .card > .card-body {
  box-shadow: 0px 4px 25px var(--card-box);
}

.sidebar-link {
  color: var(--text-default);
  text-decoration: none;
}
.sidebar-link:hover {
  color: var(--text-default);
}
.sidebar-link:focus {
  font-weight: 700;
}

.sign-up-btn {
  background-color: #5b2aef !important;
  border-color: #5b2aef !important;
  color: #ffffff !important;
  box-shadow: 1px 1px 2.5px #4a0090;
}

.sign-in-btn {
  background-color: #ffffff !important;
  border-color: #5b2aef !important;
  color: #5b2aef !important;
}

.navbar-nav > a {
  color: #000000 !important;
}

.header-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #5b2aef;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-profile-name {
  font-weight: 700;
  font-size: 20px;
}
.header-mentor-img {
  height: 20px !important;
  width: 20px !important;
}
.header-dropdown .dropdown-menu {
  transform: translate(-80px, 10px);
}
.navbar-toggler {
  border: none !important;
  padding: 0px !important;
}

.globe-icon {
  height: 17px !important;
  width: 17px !important;
}

.mentor-icon {
  width: 20px;
  height: 20px;
}
.header-btn {
  background-color: #ffff !important;
  border-color: #fff !important;
}

@media screen and (max-width: 768px) {
  .landing-nav .nav-link {
    text-align: center !important;
  }
  .header form {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 20px;
  }
  .header .btn-sm {
    margin-top: 10px;
  }
  .navbar-brand {
    width: 100px;
    height: 38px;
  }
  .globe-icon {
    height: 20px !important;
    width: 20px !important;
  }
  .header {
    padding: 0px !important;
  }
  .toggler {
    transition: transform 1s;
    transform: rotate(180) !important;
  }
  .mentor-link {
    text-align: left !important;
    margin: 10px 0px;
    font-weight: 400;
    size: 15px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-decoration: none !important;
  }
  .mentor-link > img {
    height: 20px !important;
    width: 20px !important;
  }
  .mentor-messages {
    background-color: #ff0000;
    color: #ffffff;
    text-align: center;
    border-radius: 20px;
    float: right;
    padding: 0px 10px !important;
  }
}

.indexedStep {
  text-decoration: none !important;
}

.rateinput.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
  color: var(--text-default) !important;
}
.activeSidebar .sidebar-link {
  font-weight: 700;
}
.notactiveSidebar .sidebar-link {
  color: #bcbdce !important;
}

.video-file-input {
  display: none;
}

.profile-pic-input {
  display: none;
}

.uploaded-files-list {
  margin-top: 10px;
}

.myClassName__control {
  height: 20px;
  min-height: 20px;
}

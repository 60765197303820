.live-session {
  background-color: var(--background-light);
  font-family: 'Manrope';
  font-style: normal;
  color: var(--text-default);

  .notes {
    h3 {
      img {
        margin-right: .3rem;
      }

      margin: 1rem 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: var(--text-default);
    }
  }
}

.notes-wrapper {
  .notes-editor {
    &.editor-focus {
      background: var(--white);
      border: 1px solid var(--primary);
    }

    background: var(--background-secondary);
    border: 1px solid var(--line-gray);
    padding: 14px 12px;
    border-radius: 4px;
    min-height: 190px;
  }

  .rdw-link-modal {
    height: auto !important;
  }

  .rdw-editor-toolbar {
    background-color: transparent;
    border: none;
  }

  .rdw-option-wrapper {
    padding: 10px 5px;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid transparent;

    &:hover {
      box-shadow: none;
      border: 1px solid transparent;
    }

    &.rdw-option-active {
      box-shadow: none;
      background-color: var(--white);
      border: 1px solid var(--primary);
    }
  }

  .rdw-dropdown-wrapper {
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent;
      box-shadow: none;
    }

    .rdw-dropdown-selectedtext {
      color: var(--text-secondary);
      font-weight: 600;
      text-decoration: none;
    }
  }

  .rdw-custom-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
  }
}



.chat-box {
  background-color: var(--white);

  @media screen and (min-width: 779px) {
    border-left: 1px solid var(--background-interactive-element);
  }

  .chat-header {
    border-bottom: 1px solid var(--background-interactive-element);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1rem;

    .profile {
      h3 {
        margin: 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: var(--black);
      }

      p {
        font-weight: 400;
        font-size: 14px;
        margin: 0;
        line-height: 19px;
        color: var(--text-secondary);
      }
    }
  }

  .wrapper {
    width: 100%;
  }

  .scroll {
    overflow-y: scroll;
    scroll-behavior: smooth;
    height: 100vh
  }

  .name {
    font-size: 12px;
    line-height: 26px;
    color: var(--text-secondary);
  }

  .align-items-center {
    &.text-right {
      margin-left: 1.5rem !important;
      margin-right: 0 !important;

      .name {
        text-align: right !important;
      }

      .msg {
        border-radius: 8px 8px 0px 8px !important;
        background-color: var(--background-interactive-element) !important;
      }
    }

    margin-right: 1.5rem;
    margin-left: 0 !important;

    .msg {
      border-radius: 8px 8px 8px 0px;
      background-color: var(--background-light);
      padding: 8px;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  .between {
    font-size: 12px;
    line-height: 16px;
    color: var(--text-secondary);
    margin: .7rem 0;
  }

  .navbar {
    background-color: var(--background-light);
    border-top: 1px solid var(--background-interactive-element);
    padding: .7rem;

    .input-group-text {
      right: 3px;
      top: 3px;
      cursor: pointer;
      position: absolute;
      background-color: transparent !important;
      border: none !important;
      z-index: 5;
    }

    .form-control {
      box-shadow: none;
      font-weight: 500;
      border-radius: 4px !important;
      font-size: 15px;
      line-height: 24px;
      border: 1px solid var(--line-gray);
      color: var(--text-secondary);

      &:hover {
        border-color: var(--primary);
      }
    }
  }
}
.checking {
  margin-left: 12rem !important;
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.toggle-downArrow .dropdown .btn {
  padding: 0;
  margin: 0;
  border: none !important;
  background-color: transparent;
  color: #858ab4;
}

.toggle-downArrow .dropdown .btn:hover {
  background-color: transparent;
}

.toggle-downArrow .dropdown .btn-success {
  --bs-btn-active-bg: transparent;
}

.sub-Category {
  font-weight: 500;
  color: #858ab4;
}
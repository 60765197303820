.primary-button {
    background-color: #5B2AEF !important;
    color: #ffff !important;
}
.button-text{
    background-color: #5B2AEF !important;
}
.danger-button {
    background-color: #ef2a2a !important;
    color: #ffff !important;
}

.grey-button {
    background-color: #979797 !important;
    color: #ffff !important;
}
.margin-lr {
    margin-left: 2px;
    margin-right: 2px;
}
.ig-yesno-radio {
  display: block;
  width: 50%;
  border: 1px solid var(--background-interactive-element);
  border-radius: 5px;
  input[type="radio"] {
    cursor: pointer;
    &:not(:disabled) ~ label {
      cursor: pointer;
    }
  }

  label {
    width: 100%;
    height: 53px;
    display: block;
    background: var(--white);

    position: relative;

    h3 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: var(--text-default);
      margin: 0.2rem 0;
      max-width: 90%;
    }

    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 33px;
      color: var(--text-default);
    }
  }
}

.ig-yesno-radio:has(input[type="radio"]:checked) {
  border: 2px solid var(--primary) !important;
}

.test_radio_option_row {
  .form-check-label {
    font-size: 18px;
  }
}

button.btn.btn-outline-dark.ig-contine-btn.btn.btn-primary:disabled {
  background: #4a7ac2;
}

.test-answer-block {
  b {
    font-size: 18px;
  }
}

.margin_add {
  margin: 30px;
}

.color_primary {
  background-color: #5b2aef !important;
}

.color_yellow {
  border-color: #b66d24 !important;
  background-color: #FF9933 !important;
}

.color_green {
  border-color: #267b3d !important;
  background-color: #34A853 !important;
}

.textcolor_primary {
  color: #5b2aef !important;
}

.textcolor_green {
  color: #34A853 !important;
}

.textcolor_yellow {
  color: #FF9933 !important;
}

.textcolor_red {
  color: #ef2a2a !important;
}

.textcolor_gray {
  color: #7c7c7c !important;
}

.weight_add{
  font-weight: 600;
}
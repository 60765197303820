.c_progress {
  border-radius: 0 !important;
  height: 5px;
}

.blue_progress .progress-bar {
  background-color: #4456dc !important;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.blue_progress {
  background-color: #e0e0e0 !important;
}
.progress_text {
  font-size: 16px;
  font-weight: 700;
  text-align: end;
}

.percentage_text {
  font-size: 14px;
  font-weight: 400;
}

.cent {
  align-items: center;
  justify-content: center;
}
.detailed-popup {
  color: #5b2aef !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}
.qns-popup {
  border: 2px solid #5b2aef !important;
  border-radius: 10px !important;
  transition: background-color 0.3s;
  font-weight: 700;
  font-size: 17px;
}

.qns-popup:hover {
  background-color: #5b2aef !important;
  color: white !important;
}
.qns-popup.selected {
  background-color: #5b2aef !important;
  color: white !important;
}
.deatiled-btn {
  width: 100px;
}

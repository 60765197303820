.rounded-pill.status,
.rounded-pill.category {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 10px 3px 8px;
}

.rounded-pill.category {
  background-color: var(--green) !important;
}

.rounded-pill.status.completed {
  background-color: var(--background-white) !important;
  color: var(--green) !important;
  border: 1px solid var(--green);
}

.rounded-pill.status.pending {
  background-color: var(--text-secondary) !important;
}

.rounded-pill.status.active {
  background-color: var(--green) !important;
}

.rounded-pill.status.cancelled {
  background-color: var(--red) !important;
}

body.modal-open {
  overflow: hidden;
}
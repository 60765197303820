.book-session {
  &.modal {
    .modal-header {
      border: 0;
    }

    .modal-title {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: var(--text-default);
      margin-left: 0.7rem;
    }

    .modal-body {
      .calendar-section {
        .rc {
          @media screen and (max-width: 500px) {
            width: 300px !important;
            height: 300px !important;
          }
        }

        h4 {
          margin-bottom: 0.8rem;
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: var(--text-secondary);
        }
      }
      .file-upload {
        .text-center {
          text-align: left !important;
        }
      }
      .aoi {
        .form-label {
          margin-bottom: 0.2rem;
        }
      }
      .profile-info {
        .profile-img img {
          width: 80px;
          height: 80px;
        }

        .info {
          margin: 0 !important;

          h1 {
            font-size: 18px;
            margin-bottom: 6px;
          }

          .time-zone {
            margin-bottom: 0;
          }
        }
      }

      h2 {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: var(--text-default);
        margin: 1.2rem 0 0.8rem 0;
      }

      .select-plans {
        .card-radio {
          @media screen and (max-width: 767px) {
            display: block;
            width: 100%;
          }

          label {
            @media screen and (max-width: 767px) {
              width: 100%;
              height: 78px;
            }
          }
        }
      }

      .select-times {
        @media screen and (max-width: 991px) {
          margin-top: 1rem;
        }
      }

      .time-select {
        .card-radio {
          input[type="radio"]:checked + label {
            border: 2px solid var(--primary);

            h3 {
              color: var(--text-default);
            }

            &::after {
              content: none;
            }
          }

          label {
            border-radius: 4px;
            width: 94px;
            height: 38px;
            padding: 0.3rem;
            text-align: center;
            background: var(--background-light);
            border: 1px solid var(--background-interactive-element);

            h3 {
              font-family: "Manrope";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 26px;
              color: var(--primary-dark);
              margin: 0;
            }
          }
        }
      }
    }

    .modal-footer {
      background-color: var(--background-light);
      border: none;
      justify-content: space-between;
    }
  }
}

.payment-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.sub_btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.checkout {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.stripe_success_page,
.stripe_error_page {
  border: 1px solid grey;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2.5px 2.5px 20px lightgrey;
}

.s_text {
  text-align: left;
  font-size: 18px;
  color: green;
}

.e_text {
  text-align: left;
  font-size: 18px;
  color: red;
}

.stripe_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}

.success-table th {
  text-align: right;
}

.success-table tr:first-child th {
  background: none !important;
  color: #000 !important;
}

.td-data {
  text-transform: capitalize;
}

.loader-background {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.355);
  position: fixed;
  display: block;
  opacity: 0.5;
  z-index: 1000001; // or, higher
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.td-dataDetail {
  word-break: break-all;
}

.rate-meeting {
  font-family: 'Manrope';
  font-style: normal;

  &.modal {
    .modal-header {
      border: 0;
      text-align: center;
    }

    h2 {
      font-weight: 700;
      margin-bottom: 1.2rem;
      font-size: 16px;
      line-height: 22px;
      color: var(--text-default);
    }

    .modal-title {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: var(--text-default);
      margin-left: 0.7rem;
    }

    .modal-footer {
      background-color: var(--background-light);
      border: none;
    }
  }
}

.rate-text {
  font-weight: 500;
  font-family: 'Manrope',sans-serif;
  font-size: 15px;
  color: #222222
}

.mentee-footer {
  justify-content: space-between !important;
}

.mentor-footer {
  justify-content: center;
}

.cursor-move {
  cursor: move;
}

.is-dragging {
  opacity: 0.4;
}

.single-item-drop {
  min-height: 50px;
}

.dnd-card {
  min-height: 7rem;
}

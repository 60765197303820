.modal-head .modal-title {
  font-weight: 400 !important;
}

.body-container {
  padding: 16px;
}

.body-container .info {
  color: red;
}

.body-container .declaration {
  font-weight: bold;
  font-style: italic;
}

.sig-label {
  font-style: italic;
  font-size: 14px;
}

.sig-form p {
  padding: 0;
  margin: 0;
}

.sig-form .form-label {
  display: none;
}

.box {
  font-size: 18px;
  margin-left: 4px;
  color:red;
}

.sig-form .custom-input {
  background-color: var(--white) !important;
}

.calendar-input {
  padding: 0.375rem 0.75rem;
}

.head {
  font-size: 18px;
}

.sig-form .form-check-label {
  font-size: 18px;
}

.declaration-note{
  color:red;
}
.request-card-request {
  position: relative;
  padding: 0.5rem;
  padding-left: 1rem;
  font-family: "Manrope";

  .badge {
    font-weight: 500;
  }

  .ig-resechedule {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .ACTIVE.ig-schedule {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
}

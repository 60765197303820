button {
  border: none;
  background-color: var(--white);
  margin: 4px;
}

.itemCount {
  margin-right: 10px;
}

.pageCount {
  margin-right: 10px;
  margin-left: 10px;
}

.course-details-container {
  margin: 0 10vw;
  font-family: manrope;
}

.course-info {
  background-color: rgba(91, 42, 239, 1);
  color: #ffff;
}

.asessment-title {
  font-size: 44px;
  font-weight: 700;
  color: #ffff;
}

.asessment-desc {
  font-weight: 700;
  font-size: 16px;
  color: #ffff;
}

.outcomes {
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 10px;
  text-align: justify;
}

.video-div {
  height: 200px;
  border-radius: 20px;
}

.share_div {
  border-top: 1px solid rgba(217, 217, 217, 1);
}

.right-video-div {
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #ffff;
}

.right-content {
  transform: translateY(-230px);
}

.chip {
  background-color: #ffff;
  color: rgba(121, 71, 205, 1) !important;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}

.rating-text {
  color: rgba(255, 204, 0, 1) !important;
}

.learn-more {
  cursor: pointer;
  color: rgba(121, 71, 205, 1);
}

.learn-more:hover {
  text-decoration: underline;
}

.rate-text {
  color: rgba(148, 144, 144, 1);
}

.discount {
  text-decoration: line-through;
  color: rgba(148, 144, 144, 1);
}

.p-off {
  color: rgba(219, 159, 62, 1);
}

.action-btn div {
  border: 1px solid rgba(158, 158, 158, 1);
  padding: 5px;
  border-radius: 5px;
}

.video-div {
  .plyr__time,
  .plyr__progress {
    display: none !important;
  }
}

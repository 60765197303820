.rec-stream {
  background: var(--recommendation-stream);
}

.rec-career {
  background: var(--swot-oppo);
}

.myClass li > button {
  background-color: var(--background-secondary) !important;
  color: var(--text-secondary) !important;
}

.myClass li > button.active,
.myClass li > button.active:hover,
.myClass li > button.active:focus {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  border-color: var(--primary) !important;
}

.myClass > li > button.disabled {
  background-color: var(--background-secondary) !important;
  color: var(--text-secondary) !important;
}

.myClass li:nth-child(1) > button {
  border-radius: 5px 0px 0px 0px;
}
.myClass li:nth-child(2) > button {
  border-radius: 0px 5px 0px 0px;
}

.myClass.first-tab-error li:nth-child(1) > button {
  background-color: var(--white) !important;
  border: 2px solid red !important;
  color: red !important;
}

.myClass.second-tab-error li:nth-child(2) > button {
  background-color: var(--white) !important;
  border: 2px solid red !important;
  color: red !important;
}

.tab-content {
  padding: 0.5rem;
  background-color: var(--white);
  overflow-y: auto;
}

.myClass.first-tab-error li:nth-child(1) > button {
  background-color: var(--white) !important;
  border: 2px solid red !important;
  color: red !important;
}

.myClass.second-tab-error li:nth-child(2) > button {
  background-color: var(--white) !important;
  border: 2px solid red !important;
  color: red !important;
}

.pop-up .modal-header {
  border-bottom: none;
}

.pop-up .modal-title {
  font-size: 20px;
}

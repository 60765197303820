@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --primary: #5b2aef;
  --primary-dark: #4a0090;
  --secondary: #f3904d;
  --pink: #d40192;
  --green: #34a853;
  --red: #ff0000;
  --text-gray: #bcbdce;
  --black: #000000;
  --white: #ffffff;
  --text-default: #222222;
  --text-secondary: #858ab4;
  --background-gray: #eaebf2;
  --background-light: #f4f8fb;
  --background-secondary: #eff0f6;
  --background-interactive-element: #e8eaf2;
  --bs-border-color-translucent: #e8eaf2;
  --background-white: #ffffff;
  --line-gray: #cccddd;
  --card-box: #0f172a1f;
  --border-color: #5b29f026;
  --btn-outline-secondary-border: rgba(91, 42, 239, 0.15);
  --warning-badges: #ff9900;
  --card-border: #cccedd;
  --bs-body-color: #222222;
  --swot-str: #5ed38d;
  --swot-wek: #ffc56d;
  --swot-oppo: #7db4dc;
  --swot-thr: #f56261;
  --swot-example: #344ed0;
  --recommendation-stream: #e0bbe4;
}

.btn-outline-secondary {
  --bs-btn-bg: var(--white) !important;
  border-radius: 4px;
  font-weight: 500;
  --bs-btn-font-size: 14px !important;
  --bs-btn-color: var(--primary-dark) !important;
  --bs-btn-border-color: var(--btn-outline-secondary-border) !important;
  --bs-btn-hover-color: var(--primary-dark) !important;
  --bs-btn-hover-bg: var(--btn-outline-secondary-border) !important;
  --bs-btn-hover-border-color: var(--primary-dark) !important;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: var(--primary-dark) !important;
  --bs-btn-active-bg: var(--btn-outline-secondary-border) !important;
  --bs-btn-active-border-color: var(--btn-outline-secondary-border) !important;
  --bs-btn-active-shadow: inset 0 3px 5px var(--btn-outline-secondary-border) !important;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.iguroo-main-div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-div {
  flex: 1;
  background: linear-gradient(180deg, #f4f8fb 0%, #e3eefa 100%);
}

.rounded-pill.bg-grey {
  margin-right: 10px;
  padding: 3px 8px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  --bs-badge-color: var(--black);
  background-color: var(--background-gray);
}

.btn-outline-secondary img {
  margin-right: 7.5px;
}

.link-primary {
  cursor: pointer;
  color: var(--primary-dark) !important;
  text-decoration: none;
}

.link-primary:focus,
.link-primary:hover {
  color: var(--primary) !important;
  text-decoration: none;
}

.rounded-pill.bg-warning {
  background-color: var(--warning-badges) !important;
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px;
  line-height: 18px;
  padding: 3px 8px;
}

.screen-height {
  min-height: 100vh;
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
}

.my-button {
  margin-right: 10px !important;
}

.my-button1 {
  margin-left: 10px !important;
}

.fs-8 {
  font-size: 8px;
}

.fs-10 {
  font-size: 11px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-24 {
  font-size: 25px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.primary-dark-text {
  color: #4a0090;
}

.secondary-text {
  color: #858ab4;
}

.primary-border {
  border-color: #5b2aef !important;
}

.justify-content {
  text-align: justify;
}

.ig-main-heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;

  /* text / secondary */
  color: var(--text-secondary);
}

.ig-heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--black);
  /* text-transform: capitalize; */
}

.ig-form-label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-secondary);
}

.ig-form-error-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  /* red */
  color: var(--red);
}

.bg-card {
  background-color: white !important;
}

/** custom select styles **/
.custom-select__control,
.custom-select-with-value__control,
.custom-select__control--is-focused,
.custom-select-with-value__control--is-focused {
  /* line / gray */
  border: 1px solid var(--line-gray) !important;
  border-radius: 4px;
  box-shadow: none !important;
}

.custom-select__control {
  /* background: var(--background-secondary) !important; */
  padding: 1px;
  background-color: #ffff;
}

.custom-select-with-value__control {
  background: var(--background-white) !important;
}

.custom-select__placeholder,
.custom-select-with-value__placeholder {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  /* identical to box height, or 147% */

  /* text / secondary */
  color: var(--text-secondary) !important;
}

.custom-select__single-value,
.custom-select-with-value__placeholder__single-value {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  /* identical to box height, or 147% */

  /* text / secondary */
  color: var(--text-default) !important;
}

.custom-select__indicator-separator,
.custom-select-with-value__indicator-separator {
  display: none;
}

.custom-select__option,
.custom-select-with-value__option {
  color: var(--text-default) !important;
  /* text-transform: capitalize !important; */
}

.custom-select-with-value__option--is-selected,
.custom-select__option--is-selected {
  background-color: var(--background-light) !important;
}

/** custom select styles end **/

.workExpAlert>.btn-close {
  filter: invert(1) grayscale(100%) brightness(200%) !important;
}

.ig-loader {
  border-radius: 0 !important;
  --bs-progress-height: 0.5rem !important;
  --bs-progress-bar-bg: var(--primary) !important;
}

.loader-div {
  z-index: 10;
}

.custom-select__control.custom-select__control--is-focused.custom-select__control--menu-is-open,
.custom-select-with-value__control.custom-select-with-value__control--is-focused.custom-select-with-value__control--menu-is-open {
  border: 2px solid var(--primary) !important;
}

.custom-input,
.custom-input--is-focused {
  border: 1px solid var(--line-gray);
  border-radius: 4px;
  box-shadow: none;
}

.custom-input {
  background-color: var(--background-secondary) !important;
}

.custom-input:focus {
  border: 2px solid var(--primary) !important;
  background: var(--background-white) !important;
  box-shadow: 0px 0px 8px rgba(0, 63, 213, 0.15) !important;
  border-radius: 4px;
}

.custom-input:not(:placeholder-shown) {
  background: var(--background-white) !important;
  /* border: 2px solid var(--line-gray) !important; */
}

.navbar-brand>a>img {
  width: 10rem !important;
  height: 2rem !important;
}

.navbar-brand>img {
  width: 10rem !important;
  height: 2rem !important;
}

.navbar-brand {
  display: inline-block !important;
}

.navbar-toggler {
  color: #ffff !important;
  border-color: #ffff !important;
}

.navbar-toggler:active {
  color: #ffff !important;
  border-color: #ffff !important;
}

.navbar-toggler:focus {
  color: #ffff !important;
  border-color: #ffff !important;
}

.nav-link>img {
  width: 14px;
  height: 14px;
}

.navbar-expand .navbar-collapse {
  justify-content: space-between;
}

.footer-screen {
  background-color: var(--background-secondary);
  padding: 15px !important;
  margin: 0 !important;
  align-items: center !important;
}

.custom-input .active {
  background-color: var(--background-white) !important;
}

#react-time-range {
  display: flex !important;
  padding: 0.5rem 0 0 0 !important;
}

#start-component {
  margin-right: 1rem;
}

#start-component,
#end-component {
  display: initial !important;
  width: 50%;
  padding: 0 !important;
}

#react-time-range select {
  width: 90%;
}

#start-component span,
#end-component span {
  display: block !important;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-secondary);
}

.indexedStep {
  color: var(--text-secondary);
  width: 30px;
  height: 30px;
  font-size: 12px;
  background-color: var(--background-secondary);
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(206, 206, 206);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.indexedStep.active {
  border-color: var(--primary) !important;
  background-color: var(--white);
  color: var(--primary) !important;
}

.indexedStep.accomplished {
  background-color: var(--primary) !important;
  border-style: none;
}

.RSPBprogressBar {
  height: 2px;
  width: 100%;
  line-height: 1;
  border-radius: 10px;
  position: relative;
  background-color: rgb(207, 207, 207);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  margin: 20px auto;
}

.RSPBprogressBar .RSPBstep {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: #664de5;
  z-index: -1;
}

.file-upload-label {
  padding: 10px 16px !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 4px !important;
}

.ig-heading-form {
  font-weight: 700;
  color: var(--black);
}

.text-btn-delete {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;

  color: #4a0090;
}

.text-btn-delete:hover {
  text-decoration: underline;
}

.form-check-input:focus {
  border-color: var(--line-gray) !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--line-gray) !important;
}

.form-check-input {
  background-color: var(--background-interactive-element) !important;
  border-color: var(--line-gray) !important;
  border: none !important;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.profile-pic {
  max-width: 128px;
  max-height: 128px;
}

.delete-text {
  display: inline-block;
  padding: 0px;
  margin: 0px;
  vertical-align: middle;
}

.profile-delete-text {
  color: var(--primary-dark);
}

.image-stack {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  align-items: end;
  max-width: 128px;
}

.image-stack__item--top {
  grid-column: 1 / span 1;
  grid-row: 1;
  z-index: 1;
}

.image-stack__item--bottom {
  grid-column: 1 / -1;
  grid-row: 1;
  z-index: 2;
}

.img-icon {
  width: 32px;
  height: 32px;
  background: var(--primary);
  border-radius: 99px;
  align-items: center;
}

.footer-logo-img>a>img {
  width: 150px;
  height: 50px;
}

.footer-text {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.footer-text>a {
  text-decoration: none;
  color: var(--primary-dark);
  font-weight: 400;
  font-size: 15px;
  margin: 0px 10px;
  line-height: 2.5;
}

.footer-icons {
  text-align: center;
}

.footer-icons>a>img {
  width: 15px;
  height: 20px;
  margin: 15px;
}

@media screen and (max-width: 768px) {
  .footer-logo-img {
    text-align: center;
  }

  .footer-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 15px;
  }

  .footer-logo-img {
    margin-top: 15px;
  }

  .footer-icons>img {
    width: 20px !important;
    height: 20px !important;
  }

  .book-now-btn {
    width: 100%;
  }
}

.completed-popup .modal-content {
  padding: 20px;
}

.completed-popup .modal-dialog {
  margin-top: 15em;
  background: var(--background-white);
  box-shadow: 0px 4px 25px rgba(15, 23, 42, 0.12);
  border-radius: 8px;
}

.completed-popup .modal-content>img {
  width: 50px;
  margin: auto;
}

.completed-popup .btn-secondary {
  width: 100%;
  height: 40px;
  margin: auto;
  background: var(--primary);
  border-bottom: 2px solid var(--primary-dark);
  border-radius: 4px;
}

.completed-popup .btn-secondary:hover {
  background: var(--primary);
  border-bottom: 2px solid var(--primary-dark);
}

.completed-popup .modal-body {
  font-size: 13px;
  margin: auto;
}

.completed-popup .modal-content>.pop-head {
  font-size: 25px;
  margin: auto;
  font-weight: 500;
}

.completed-popup .popup-body {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: var(--text-default);
}

.cursor-pointer {
  cursor: pointer !important;
}

.skip-btn {
  background: #ffffff;
  border: 1px solid rgba(91, 42, 239, 0.15);
  border-radius: 4px;
}

.side-nav a {
  font-size: 14px !important;
  color: #222222 !important;
  font-weight: 400;
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
}

.resetBtn {
  font-size: 14px;
  line-height: 19px;
  color: var(--text-gray);
  text-align: center;
  color: var(--text-gray);
}

.ig-form-heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  background: #ffffff;
  border: 1px solid rgba(91, 42, 239, 0.15);
  border-radius: 4px;
}

.ig-card-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  text-transform: capitalize;
  text-align: center;
  color: var(--text-default);
}

.ig-card-sec-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: var(--text-secondary);
}

.ig-card-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: var(--text-default);
}

.ig-icon-label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--text-default);
}

.ig-card-rate {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--text-default);
}

.ig-card-duration {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--text-default);
}

.side-nav .active {
  font-weight: 600;
  background-color: #f4f8fb;
  border-right: 3px solid #5b2aef;
  cursor: pointer;
}

.side-nav-icon {
  height: 20px !important;
  width: 20px !important;
  margin-right: 10px;
  cursor: pointer;
}

.message-badge {
  float: right;
}

.mentee-profile-img {
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.mentee-profile-info {
  text-align: center;
}

.mentee-profile-name {
  font-family: Manrope;
  font-style: Bold;
  font-size: 24px;
  line-height: 33px;
  line-height: 100%;
  color: #000000;
  text-transform: capitalize;
}

.mentee-profile-info-list li {
  color: #858ab4;
  font-family: manrope;
  font-size: 14px;
}

.mentee-profile-info-btn {
  background-color: #5b2aef;
  color: #ffff;
  border: #5b2aef;
  box-shadow: 1px 1px 1px #4a0090;
}

.mentee-profile-info-btn:hover {
  background-color: #5b2aef;
  color: #ffff;
  border: #5b2aef;
  box-shadow: 1px 1px 1px #4a0090;
}

.mentee-details-card .card-header {
  background-color: #ffff !important;
}

.orange-badge.badge.rounded-pill {
  background-color: #ff9900 !important;
}

.green-badge.badge.rounded-pill {
  background-color: #34a853 !important;
}

.mentee-progress .progress-bar {
  background-color: #ff9900 !important;
}

.mentee-card-date {
  color: #858ab4;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important;
}

.dashboard-breadcrumb a {
  color: #4a0090 !important;
  text-decoration: none !important;
  text-transform: capitalize;
}

.dashboard-breadcrumb .active-link {
  color: #858ab4 !important;
  pointer-events: none;
  cursor: default;
}

.mentor-profile-img {
  border-radius: 50%;
  border: 5px solid #e8eaf2;
}

.green-circle {
  background-color: #34a853;
  border: 5px solid #e8eaf2;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  transform: translate(-50px, 160px);
}

.mentor-btn {
  background-color: transparent !important;
  border: none !important;
  color: var(--primary-dark) !important;
}

.book-now-btn {
  background-color: #5b2aef !important;
  border-color: #5b2aef !important;
  color: #ffff !important;
}

.profile-name-dropdown .dropdown-toggle::after {
  display: none;
}

.profile-name-dropdown .btn {
  background-color: #ffff !important;
  border-color: #ffff !important;
  cursor: default;
}

.header .dropdown-menu {
  transform: translateX(-100px);
}

.ig-bookmark {
  font-size: 12px;
  line-height: 19px;
  color: var(--primary-dark) !important;
}

.ig-card-verification-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: var(--text-default);
}

.ig-auth-link {
  color: var(--primary-dark) !important;
}

.ig-auth-heading {
  color: var(--text-default);
  text-transform: uppercase;
  font-weight: 900;
  font-size: 24px;
}

.ig-back-btn {
  background: transparent;
  border: none !important;
  border-radius: 4px;
}

.ig-back-btn:hover {
  background: var(--background-white) !important;
  border: none;
  border-radius: 4px;
  color: var(--text-default);
}

.custom_input {
  background: var(--background-secondary);
  width: 280px;
  height: 40px;
}

.profile-title.ig-card-text.mt-2 {
  min-height: 40px;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1390px) and (min-width: 786px) {
  .profile-title.ig-card-text.mt-2 {
    min-height: 40px;
    line-height: 18px;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.signin-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(15, 23, 42, 0.12);
  border-radius: 8px;
}

.signin-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: 300px;
  height: 52px;
  background: #ffffff;
  border: 1.5px solid #000000;
  border-radius: 4px;
  font-weight: 800;
  font-size: 18px;
  color: #000000;
  font-family: "Manrope";
}

.signin-link>a {
  color: #4a0090;
}

.custom_text,
.custom_input,
.custom_text--is-focused,
.custom_input--is-focused {
  border: 1px solid var(--line-gray) !important;
  border-radius: 4px;
  box-shadow: none !important;
}

.custom_input:focus {
  border: 1px solid var(--primary) !important;
  background: var(--background-white) !important;
}

.custom_input:valid {
  background: var(--background-white) !important;
  border: 1px solid var(--line-gray) !important;
}

.sigin-pwd>a {
  color: #4a0090;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .signin-top {
    width: 350px;
  }
}

.search-field label.custom-input-text.form-label {
  display: none !important;
}

span.search-icon.input-group-text {
  border-right: none;
  background: var(--background-secondary);
  height: 40px;
}

.search-field input.custom-input:active,
.search-field input.custom-input:focus {
  border-left: 2px solid var(--primary) !important;
}

.settings-menu-list {
  list-style-type: none;
  padding: 0;
}

.settings-menu-list li {
  cursor: pointer;
}

.account-settings {
  border-right: 2.5px solid #5b2aef;
  background-color: #f4f8fb;
  color: var(--text-default);
}

.categorey-item {
  cursor: pointer;
  color: var(--text-default);
}

.file-size {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  /* text / secondary */

  color: var(--text-secondary);
}

.ig-session-detail {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.ig-session-detail.CANCELLED {
  color: var(--text-secondary);
}

.ig-session-detail.COMPLETED {
  color: var(--primary-dark);
}

.ig-reject-btn {
  color: var(--red) !important;
  border: 1px solid var(--red) !important;
}

.ig-session-join {
  background: #ffffff;
  border: 1px solid rgba(91, 42, 239, 0.15);
  border-radius: 4px;
}

.ig-accordion-header .accordion-button {
  background: var(--background-light) !important;
  color: var(--text-secondary) !important;
  font-weight: 700;
}

.or-tag {
  color: var(--text-secondary);
  font-weight: 400;
  font-size: 16px;
}

.or-tag:before,
.or-tag:after {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
  margin: auto 0.5em;
  color: var(--background-gray);
}

.verify-page {
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(15, 23, 42, 0.12);
  border-radius: 8px;
}

.verify-page {
  box-shadow: 0px 4px 25px rgba(15, 23, 42, 0.12);
  border-radius: 8px;
}

.input-group>.password-input {
  border-right: none !important;
}

.input-group>.custom-input-text {
  border: none !important;
}

span.password-icon.input-group-text {
  background-color: white;
}

.fade.sign-head.modal.show {
  background: linear-gradient(180deg, #f4f8fb 0%, #e3eefa 100%);
  min-height: 100vh;
  padding-top: 25px;
}

button.signin-btn.btn.btn-primary {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1.5px solid #000000;
  border-radius: 4px;
  font-weight: 800;
  font-size: 15px;
  color: #000000;
  font-family: "Manrope";
  border-bottom: 1px solid var(--black) !important;
}

.language-select .custom-select__control,
.language-select .custom-select-with-value__control,
.language-select .custom-select__control--is-focused,
.language-select .custom-select-with-value__control--is-focused {
  border: none !important;
}

.language-select .custom-select__control {
  background: none !important;
}

.language-select .custom-select__single-value,
.language-select .custom-select-with-value__single-value {
  text-transform: capitalize !important;
  font-size: 15px;
}

.language-select .custom-select__control.custom-select__control--is-focused.custom-select__control--menu-is-open,
.language-select .custom-select-with-value__control.custom-select-with-value__control--is-focused.custom-select-with-value__control--menu-is-open {
  border: none !important;
}

.margin-label-offset {
  margin-top: 0.4em;
}

.ig-req-detail-session-badge {
  --bs-badge-padding-y: 0.45em !important;
}

.accordion-button::after {
  background-image: url("../assets/images/arrow-down.svg") !important;
}

.sign-head.row {
  padding-top: 25px;
}

.signin-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: 300px;
  height: 52px;
  background: #ffffff;
  border: 1.5px solid #000000;
  border-radius: 4px;
  font-weight: 800;
  font-size: 18px;
  color: #000000;
  font-family: "Manrope";
}

.signin-link>a {
  color: #4a0090;
}

/* Calendar styles */
.rc_root {
  background-color: var(--background-light);
  font-family: "Manroot";
  font-size: 16px;
  border-radius: 8px;
  padding: 0.4rem;
}

.rc_root .rc_header {
  border-bottom: 1px solid var(--background-interactive-element);
  height: 58px !important;
}

.rc_root .rc_header .rc_header_nav span {
  font-size: 0;
}

.rc_root .rc_header .rc_header_nav span::before,
.rc_root .rc_header .rc_header_nav span::after {
  color: var(--text-secondary);
  font-size: 16px;
}

.rc_root .rc_header .rc_header_nav-next span::before {
  content: url(../assets/images/date-next.svg);
}

.rc_root .rc_header .rc_header_nav-prev span::before {
  content: url(../assets/images/date-prev.svg);
}

.rc_root .rc_header .rc_header_label {
  font-family: "Manrope";
  font-style: normal;
  color: var(--text-default);
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

.rc_root .rc_body-weekdays {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: var(--text-secondary);
}

.rc_root button {
  font-family: "Manrope";
  border-radius: 4px;
  border: none;
  height: 100% !important;
  background-color: transparent;
}

.rc_root .rc_highlight.rc_disabled button {
  background-color: var(--text-secondary);
  color: var(--background-white) !important;
}

.rc_root .rc_highlight button {
  color: var(--background-white);
  background-color: var(--primary);
}

.rc_root .rc_grey button {
  color: var(--background-white);
  background-color: var(--text-secondary);
}

.rc_root .rc_disabled button:disabled {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--text-gray);
}

.rc_root .rc_selected button {
  background-color: var(--background-white);
  border: 2px solid var(--primary);
  color: var(--primary) !important;
  font-weight: 700;
  padding: 0;
}

/* Calendar styles ends */

.OTPSuccess .modal-body,
.OTPSuccess .modal-footer {
  border: none !important;
}

.ig-OTP-field input {
  width: 100% !important;
  height: 50px;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 100;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  border: 1px solid var(--line-gray);
  border-radius: 4px;
  color: #222222;
}

.ig-otp-row div {
  justify-content: center !important;
}

.btn-primary {
  --bs-btn-bg: var(--primary) !important;
  --bs-btn-border-color: var(--primary) !important;
  border-bottom: 2px solid var(--primary-dark) !important;
  --bs-btn-hover-bg: var(--primary) !important;
  --bs-btn-hover-border-color: var(--primary) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-bg: var(--primary) !important;
  --bs-btn-active-border-color: var(--primary) !important;
  --bs-btn-active-shadow: none;
}

.btn-outline-primary {
  --bs-btn-color: var(--primary) !important;
  --bs-btn-border-color: transparent !important;
  --bs-btn-hover-color: var(--primary) !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-active-color: var(--primary) !important;
  --bs-btn-active-bg: transparent !important;
  --bs-btn-active-border-color: transparent !important;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: var(--primary);
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-disabled-border-color: var(--primary);
}

input.custom-input.password-input.form-control {
  width: 225px;
}

input.custom-input.sign-input.ig-error-signin {
  background: #fff6f5 !important;
  border: 1px solid #ff0000 !important;
  border-radius: 4px;
}

.ig-password-field .password-icon {
  border-left: none !important;
  border-bottom-left-radius: none !important;
  background-color: var(--background-secondary) !important;
  border-top-left-radius: none !important;
}

.ig-password-field input.custom-input.sign-input {
  border-right: none !important;
  border-bottom-right-radius: none !important;
  border-top-right-radius: none !important;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.ig-password-field input.custom-input.sign-input:focus {
  border-right: 2px solid var(--primary) !important;
}

.ig-password-field input.custom-input.sign-input.ig-error-signin {
  background: #fff6f5 !important;
  border-right: none !important;
  border-left: 1px solid #ff0000 !important;
  border-top: 1px solid #ff0000 !important;
  border-bottom: 1px solid #ff0000 !important;
  border-bottom-right-radius: none !important;
  border-top-right-radius: none !important;
}

.ig-password-field .password-icon.ig-error-signin {
  background: #fff6f5 !important;
  border-right: 1px solid #ff0000 !important;
  border-top: 1px solid #ff0000 !important;
  border-bottom: 1px solid #ff0000 !important;
  border-bottom-left-radius: none !important;
  border-top-left-radius: none !important;
  border-left: none !important;
}

.ig-sign-heading {
  color: var(--text-default);
  font-weight: 900;
  font-size: 19px;
}

.ig-sign-heading {
  color: var(--text-default);
  font-weight: 900;
  font-size: 19px;
}

.ig-onboarding-head {
  font-size: 20px;
}

.ig-onboarding-abovehead {
  font-size: 22px;
}

.submit-btn.btn:disabled {
  background-color: #eaebf2 !important;
  border: none;
  border-bottom: 2px solid #cccddd !important;
  color: var(--text-gray) !important;
  font-weight: 700;
}

.or-tag-name {
  color: var(--text-secondary);
  font-weight: 400;
  font-size: 16px;
}

input.custom-input.sign-input.form-control[value="+91"] {
  background-color: var(--background-secondary) !important;
}

.ig-OTP-field input:focus,
.ig-OTP-field input:focus-visible {
  border: 2px solid var(--primary) !important;
  outline-color: var(--primary) !important;
}

.setting-page .profile-pic {
  max-width: 98px;
  max-height: 98px;
}

.setting-page .image-stack {
  max-width: 98px;
}

.setting-page .profile-delete-text {
  font-size: 14px;
}

.ig-radio-disable .radio_option_row.form-check.form-check-inline {
  color: var(--text-gray);
}

.ig-radio-disable .form-check-input:checked {
  background-color: var(--line-gray) !important;
  border-color: var(--line-gray) !important;
}

input.custom-password-with-icon.custom-input.security-password.form-control {
  background-color: var(--white) !important;
  border-right: none !important;
}

span.security-password.input-group-text {
  background-color: var(--white);
  border-left: none;
}

input.custom-password-with-icon.custom-input.security-password.form-control:focus {
  border: 1px solid var(--line-gray) !important;
  box-shadow: none !important;
  border-right: none !important;
}

.id-security-head {
  color: var(--text-default);
}

@media screen and (min-width: 990px) {
  .save-btn-end>.d-grid {
    justify-content: flex-end !important;
  }
}

.ig-file-name a {
  color: var(--text-default) !important;
  text-decoration: none;
  font-size: 14px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ig-request-heading {
  color: var(--primary-dark);
  font-size: 16px;
  font-weight: 600;
}

.fade.completed-popup.OTPSuccess.modal.show {
  background: linear-gradient(180deg, #f4f8fb 0%, #e3eefa 100%);
}

.ig-file-col {
  overflow: hidden;
  white-space: nowrap;
}

.ig-name-length {
  color: var(--text-gray);
}

.ig-mentor-img {
  position: relative;
}

.ig-bookmark {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  padding: 2.5px;
  border-radius: 50%;
}

.rating-star,
.rating-hours {
  display: inline;
}

.rating-star img {
  margin-left: 5px;
}

.plyr {
  border-radius: 10px !important;
}

.mentor-profile {
  cursor: pointer;
}

.ig-session-join-disable {
  background-color: #ced4da !important;
  cursor: default;
}

.ig-aggrid-css .ag-header-cell-text {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-secondary);
}

.ig-aggrid-css .ag-header {
  background-color: transparent;
}

.ig-aggrid-css .ag-root-wrapper {
  border: none;
}

.ig-aggrid-css .ag-body-viewport.ag-row-animation.ag-layout-normal::-webkit-scrollbar,
.ig-aggrid-css .ag-body-viewport.ag-row-animation.ag-layout-normal::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px #fff;
  background-color: var(--primary);
}

.ig-aggrid-css .ag-body-viewport.ag-row-animation.ag-layout-normal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
  background-color: #fff;
}

.ig-aggrid-css .ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
.ig-aggrid-css .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
  height: 5px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px #fff;
  background-color: var(--primary);
}

.ig-aggrid-css .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
  background-color: #fff;
}

.ig-aggrid-css .ag-cell.ag-cell-value {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: #222222;
  display: flex;
  align-items: center;
}

.ig-agGrid-username {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--primary-dark) !important;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.form-control {
  border-radius: 4px !important;
}

.ig-resechedule {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ff0000;
}

.ACTIVE.ig-schedule {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #34a853;
}

.back-link {
  text-decoration: none;
  color: #5b2aef;
}

.back-link:hover {
  text-decoration: none;
  color: #5b2aef;
}

.ag-row {
  height: 85px !important;
}

.ig-aggrid-css .user-card .info {
  display: flex;
  align-items: center;
}

.ig-bookmark-name {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #222222;
}

.ig-bookmark-viewall {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #5b2aef;
}

.description::first-letter {
  text-transform: capitalize !important;
}

.name-firstletter {
  text-transform: capitalize !important;
}

.ig-aggrid-css::first-letter {
  text-transform: uppercase !important;
}

.a_rating_div {
  height: 30px;
  width: 30px;
  background-color: #d9d9d9;
  color: #868686;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 5px;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 14px;
}

.a_rating_div_2 {
  background-color: #ad35cb !important;
  color: #ffff !important;
}

.a_rating {
  flex-wrap: wrap;
}

.a_rating_text {
  font-weight: 600;
  font-size: 20px;
}

.info_card {
  font-family: Roboto, sans-serif;
}

.info_card .card-header {
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.info_card .card-body {
  font-weight: 500 !important;
  font-size: 14px !important;
  border-radius: 7px;
}

.info_card_blue {
  border-color: rgba(39, 73, 160, 1) !important;
}

.info_card_blue .card-header {
  background-color: rgba(39, 73, 160, 1) !important;
  color: #ffff !important;
}

.info_card_blue .card-body {
  background-color: #f2f2f2 !important;
  color: rgba(53, 52, 52, 1) !important;
}

.info_card_purple {
  border-color: rgba(201, 84, 231, 1) !important;
}

.info_card_purple .card-header {
  background-color: rgba(201, 84, 231, 1) !important;
  color: #ffff !important;
}

.info_card_purple .card-body {
  background-color: rgba(242, 242, 242, 1) !important;
  color: rgba(53, 52, 52, 1) !important;
}

.info_card_orange {
  border-color: rgba(232, 137, 86, 1) !important;
}

.info_card_orange .card-header {
  background-color: rgba(232, 137, 86, 1) !important;
  color: #ffff !important;
}

.info_card_orange .card-body {
  background-color: rgba(242, 242, 242, 1) !important;
  color: rgba(53, 52, 52, 1) !important;
}

.info_card_cyan {
  border-color: rgba(37, 159, 172, 1) !important;
}

.info_card_cyan .card-header {
  background-color: rgba(37, 159, 172, 1) !important;
  color: #ffff !important;
}

.info_card_cyan .card-body {
  background-color: rgba(242, 242, 242, 1) !important;
  color: rgba(53, 52, 52, 1) !important;
}

.info_card_dark_cyan {
  border-color: rgba(5, 119, 115, 1) !important;
}

.info_card_dark_cyan .card-header {
  background-color: rgba(5, 119, 115, 1) !important;
  color: #ffff !important;
}

.info_card_dark_cyan .card-body {
  background-color: rgba(242, 242, 242, 1) !important;
  color: rgba(53, 52, 52, 1) !important;
}

.info_card_maroon {
  border-color: rgba(184, 52, 99, 1) !important;
}

.info_card_maroon .card-header {
  background-color: rgba(184, 52, 99, 1) !important;
  color: #ffff !important;
}

.info_card_maroon .card-body {
  background-color: rgba(242, 242, 242, 1) !important;
  color: rgba(53, 52, 52, 1) !important;
}

.info_card_brown {
  border-color: rgba(151, 82, 82, 1) !important;
}

.info_card_brown .card-header {
  background-color: rgba(151, 82, 82, 1) !important;
  color: #ffff !important;
}

.info_card_brown .card-body {
  background-color: rgba(242, 242, 242, 1) !important;
  color: rgba(53, 52, 52, 1) !important;
}

.ig-assessment-buttonftr {
  background: #f4f8fb;
  border: 1px solid #e8eaf2;
  border-radius: 8px;
}

.ig-backCancel-btn {
  background-color: #ffffff !important;
  border: 1px solid rgba(91, 42, 239, 0.15) !important;
  border-radius: 4px;
  color: var(--primary-dark) !important;
}

.ig-reset-btn {
  background-color: red !important;
  border: 1px solid rgba(91, 42, 239, 0.15) !important;
  border-radius: 4px;
  color: white !important;
}

.firstButtonReset {
  display: none !important;
}

.ig-contine-btn {
  color: #ffffff !important;
}

.ig-question-progress .progress {
  height: 4px !important;
}

.ig-test-question {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  min-height: 80px;
  color: #000000;
}

.content-bgnone {
  flex: 1;
}

.ig-test-Ratequestion {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  color: #000000;
}

.ig-self-discovery {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: none;
  padding: 2.5px;
  border-radius: 50%;
}

span.ig-question-progress.align-items-center {
  width: 10%;
}

.ig-exam-qImag img {
  max-width: 100%;
  border: 1px solid var(--text-secondary);
  border-radius: 10px;
}

@media screen and (min-width: 768px) {
  .ig-exam-qSection {
    border-left: 2px solid var(--text-secondary);
  }
}

@media screen and (max-width: 768px) {
  .ig-exam-qSection {
    margin-top: 20px !important;
    border-top: 2px solid var(--text-secondary);
  }
}

p.ig-rate-hint {
  font-size: 16px;
  color: var(--text-secondary);
  font-weight: 500;
  font-style: italic;
}

.ig-exam-video .plyr__video-wrapper {
  border: 1px solid var(--text-secondary);
  box-shadow: 5px 5px 5px rgba(0, 63, 213, 0.15) !important;
}

.ig-selfdiscovery-howerbtn {
  position: absolute;
  bottom: 10%;
  text-align: center;
  opacity: 1;
  transition: opacity 0.35s ease;
  color: #ffffff;
  border: 1px solid;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.container:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.ig-mentor-img:hover .ig-selfdiscovery-howerbtn {
  opacity: 1;
}

.ig-exam-qImag,
.ig-exam-qSection {
  flex-grow: 1;
}

.ig-exam-ques {
  display: flex;
  flex-wrap: wrap;
}

.ig-box {
  height: 100%;
}

.ig-questaboutText-abovehead {
  font-weight: 700;
  color: red;
}

.assess-popupCard {
  border: 4px solid #cccddd !important;
  border-radius: 2px !important;
}

.assessPopup-head {
  font-weight: 700;
  font-size: 20px;
  white-space: nowrap;
}

.assessPopup-footer {
  border: solid white !important;
}

.assess-body>.page_container>.back-button {
  display: none !important;
}

.assess-body>.page_container>.page_2_btn_div>.book_session_btn {
  display: none !important;
}

.assess-popup .book_session_btn {
  display: none;
}

.assess-popup .page_container .session-button {
  display: none !important;
}

.assess-popup .a_page .ellipse_1 {
  transform: translate(500px, -60px);
}

.no-padding .page_container {
  padding: 0 !important;
}

.custom-modal {
  max-width: 75% !important;
  overflow: hidden !important;
}

.mentor-name-font {
  text-transform: capitalize !important;
  font-size: 18px;
  font-weight: 700;
}

.assess-body {
  overflow: hidden !important;
}

.fixed-navbar {
  background-color: white !important;
}

.lang-popup {
  margin-left: 60px;
  text-align: initial;
  font-style: Bold;
}

.popup-body>.lang-popup>.radio-lang-popup {
  color: #198a30 !important;
}

.radio-popup-lang:checked {
  accent-color: var(--primary) !important;
}

.popup-body input[type="radio"]:checked {
  background-color: #4caf50 !important;
}

.ig-test-aboutQuestion {
  text-align: justify;
}

.lang-popup-profile {
  text-align: start;
  font-style: Bold important;
  font-size: 14px;
}

.lang-popup-profile-title {
  text-align: start;
  margin-top: 30px !important;
  margin-bottom: 0px !important;
  font-style: Bold !important;
  font-size: 16px;
  margin-left: 10px;
  font-weight: 700;
}

.lang-popup-profile2 {
  text-align: start !important;
  font-style: normal !important;
  font-size: 13px !important;
  margin-left: 15px !important;
  flex: auto;
  font-weight: 700;
}

.lang-popup-profile2 input[type="radio"] {
  margin-right: 5px;
}

.card-testimonilas {
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  color: #4a0090;
  border-radius: 4px !important;
  align-items: center !important;
  justify-content: center !important;
  align-content: center !important;
}

.testmonials-head {
  color: #5b2aef;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 22px !important;
  text-align: center !important;
}

textarea.custom-input.card-testimonilas.form-control {
  font-size: 16px;
  text-align: start;
  border: 2px solid #5b2aef !important;
  background-color: white !important;
}

button.rc_header_label.rc_header_label-days-of-month>div:first-of-type {
  margin-right: 5px;
}

.mentee-profilepic-img {
  border-radius: 50%;
  border: 2px solid #e8eaf2;
}

.cursor-move {
  user-select: none;
}

.time-border {
  border: 1px solid var(--line-gray);
  border-radius: 4px;
  box-shadow: none;
  height: 38px;
  border-right: none;
}

.time-border:focus {
  border: 2px solid var(--primary) !important;
  background: var(--background-white) !important;
  box-shadow: 0px 0px 8px rgba(0, 63, 213, 0.15) !important;
  border-radius: 4px;
}

.signin-top-reset-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: #ffffff;
  border-radius: 8px;
}

.popup-resetpassword-message {
  font-weight: bold;
}

.dueDate-text {
  font-size: 12px;
}

.validtill-date {
  font-size: 12px;
}

.validDate {
  background-color: #eee;
  color: #6c757d;
}

.duedateExpired {
  background-color: rgb(215, 61, 61);
  color: #fff;
}

.title span {
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 50px;
}

.title span:nth-child(2) {
  color: var(--warning-badges);
}

.author {
  font-size: 14px;
  color: #6c757d;
}

img.rounded-img.assessment-select {
  width: 100%;
}

.quickDetailPopup {
  margin-left: 5%;
  margin-right: 5%;
}

.content-div.signup-background {
  background-image: var(--img);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.feedback-table .ag-cell-value {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.back-btn {
  border: 2px solid #5b2aef !important;
  color: #5b2aef !important;

}

.back-btn:hover {
  border: 1px solid #5b2aef !important;
  /* background: #FFFFFF; */
  background-color: #5b2aef !important;
  color: #fff !important;
  /* border-radius: 15px; */

}
.status-button-myassessment .btn {
  border-radius: 0;
  min-width: 90px;
  min-height: 45px;
  border: 1px solid #e8eaf2;
}

.status-button-myassessment .btn-primary {
  border-bottom: 1px solid #e8eaf2 !important;
}

.title p {
  font-weight: bold;
}

.title p:nth-child(2) {
  color: var(--warning-badges);
}

.author {
  font-size: 12px;
}

.rating p {
  font-size: 14px;
}

.img-container .dropdown {
  position: absolute;
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.img-container .dropdown .btn {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  transform: rotate(90deg);
}

.img-container .dropdown .btn:hover {
  background-color: transparent;
}

.img-container .dropdown .btn-success {
  --bs-btn-active-bg: transparent;
}

.assessProgress{
  height: 5px !important;
  color: var(--bs-progress-bar-color) !important;
  --bs-progress-bar-bg: #5B2AEF !important;
}
.assessmentActive {
  font-weight: 500;
  color: white;
  border: 1px solid #6610f2;
  border-radius: 4px;
  background-color: #6610f2;
}

.author span {
  font-size: 14px;
  color:#6c757d;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 25px;
}
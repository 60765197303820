.request-card {
  position: relative;
  padding: 0.5rem;
  padding-left: 1rem;
  font-family: "Manrope";
  cursor: pointer;
  .badge {
    font-weight: 500;
  }

  .progress-container {
    position: relative;
    left: 3.7rem;
    width: 68%;

    .progress {
      height: 6px;
      margin-top: 11px;
      border-radius: 8px;

      .bg-warning {
        background-color: var(--warning-badges) !important;
      }
    }

    span {
      position: absolute;
      font-style: normal;
      font-weight: 400;
      top: -0.4rem;
      right: -2rem;
      font-size: 12px;
      line-height: 16px;
    }
  }

  h3 {
    .play {
      position: absolute;
      left: -5px;
      top: 12px;
    }
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    min-height: 44px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.request-card::first-letter {
  text-transform: capitalize;
}

.courseProgress-text{
    font-size: 16px;
    text-align: left;
}
.course-progress{
    /* border-radius: 8%; */
}
.course-progress .progress-bar {
    background-color: #0bbf41;
}
.ig-courses-subtitle{
    color: #858ab4;
}
.course-videos{
    height: 460px !important;
}
.course-card.active{
    background-color: #d0e0ed;
}
.ig-course-URL-title{
    font-size: 18px;
    
}
.courses-pdf .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    /* height: 100% !important; */
}
.ig-course-title{
    font-weight: 700;
}
:root {
  --gray: #d0d2d3;
}

.white_bg {
  background-color: white !important;
}

.user_title_main {
  font-size: 24px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
}

.user_title {
  font-size: 24px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
}

.user_subtitle {
  font-size: 20px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
}

.list_subtitle {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.list_title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.color-primary {
  color: #5b2aef;
}

.color-black {
  color: #000;
}

.color-gray {
  color: #545454;
}

.al_center {
  justify-content: center;
  align-items: center;
}

.al_left {
  justify-content: left;
  align-items: left;
}

.user_desc {
  font-size: 16px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  align-items: start;
  justify-content: start;
}

.sub_btn {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}

.checkout {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.qSection {
  border-left: 1.2px solid var(--gray);
}

.radio_option_row {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.custom_radio_option_label {
  color: #858ab4;
  font-weight: 600;
  list-style-type: none;
}
/* Inactive tab */
.myClass li > button {
  background-color: var(--background-secondary) !important;
  color: var(--text-secondary) !important;
}

.my-example-tab {
  border: 0px !important;
}
/* .myClass ul > li > a:hover {
  background-color: pink;
} */

/* Active tab */
.myClass li > button.active,
.myClass li > button.active:hover,
.myClass li > button.active:focus {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  border-color: var(--primary) !important;
}

/* Disabled tab */
.myClass > li > button.disabled {
  background-color: var(--background-secondary) !important;
  color: var(--text-secondary) !important;
}

.myClass li:nth-child(1) > button {
  border-radius: 5px 0px 0px 0px;
}
.myClass li:nth-child(2) > button {
  border-radius: 0px 5px 0px 0px;
}

.myClass.first-tab-error li:nth-child(1) > button {
  background-color: var(--white) !important;
  border: 2px solid red !important;
  color: red !important;
}

.myClass.second-tab-error li:nth-child(2) > button {
  background-color: var(--white) !important;
  border: 2px solid red !important;
  color: red !important;
}

.my-example-tab li > button {
  border-radius: 0px 0px 0px 0px !important;
}
.my-example-tab li > button {
  color: var(--white) !important;
  border: 1px solid var(--line-gray) !important;
  border-color: var(--line-gray) !important;
}
.my-example-tab.example-str-tab li > button {
  background-color: var(--swot-str) !important;
  color: var(--white) !important;
}

.my-example-tab.example-wek-tab li > button {
  background-color: var(--swot-wek) !important;
  color: var(--white) !important;
}

.my-example-tab.example-oppo-tab li > button {
  background-color: var(--swot-oppo) !important;
  color: var(--white) !important;
}

.my-example-tab.example-thr-tab li > button {
  background-color: var(--swot-thr) !important;
  color: var(--white) !important;
}

.my-tab-content {
  border: 1px solid var(--line-gray);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 5px 5px;
  padding: 1rem;
  background-color: var(--white);
  height: 16rem;
  overflow-y: auto;
}

.swot-strength {
  background: var(--swot-str);
}

.swot-weakness {
  background: var(--swot-wek);
}

.swot-opportunity {
  background: var(--swot-oppo);
}

.swot-threat {
  background: var(--swot-thr);
}

.my-semi-circle {
  width: 50px;
  height: 50px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;

  /* identical to box height */
  text-align: center;

  color: var(--white);
}
.my-semi-circle-left-top {
  border-radius: 100px 0px 0px 0px;
  background: rgba(53, 191, 201, 0.75);
}

.my-semi-circle-right-top {
  border-radius: 0px 100px 0px 0px;
  background: rgba(239, 179, 15, 0.75);
}

.my-semi-circle-right-bottom {
  border-radius: 0px 0px 100px 0px;
  background: rgba(252, 122, 97, 0.75);
}

.my-semi-circle-left-bottom {
  border-radius: 0px 0px 0px 100px;
  background: rgba(99, 196, 250, 0.75);
}

.msc-right-bottom {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.msc-left-bottom {
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.msc-right-top {
  margin-top: 0.5rem;
  margin-right: 0.5em;
}

.msc-left-top {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.swot-popup-example .modal-dialog {
  max-width: 80em;
}
.swot-popup .modal-dialog {
  max-width: 45em;
}
.swot-popup .modal-title,
.swot-popup-example .modal-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;

  /* identical to box height, or 92% */
  text-align: center;

  color: var(--primary) !important;
}

.swot-popup .modal-body {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  color: var(--black);
}

.swot-example-button {
  background: var(--white) !important;
  border: 1px solid var(--swot-example) !important;
  border-radius: 4px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: var(--swot-example) !important;
}

.swot-input {
  border-radius: 0px 5px 5px 0px !important;
  border-left: 0px !important;
}

.swot-single-example {
  background: var(--background-secondary);
  border: 1px solid var(--line-gray);
  border-radius: 2px;
}

.swot-single-icon {
  max-height: 1.3rem;
}

.img-container {
  position: relative;
}

.icon-container {
  top: 0;
  right: 0;
}

.publisherContainer p {
  padding: 0;
  margin: 0;
}

.three-dots {
  color: black;
  font-size: 30px !important;
}

.status-button .btn {
  border-radius: 0;
  min-width: 145px;
  min-height: 45px;
  border: 1px solid #e8eaf2;
}

.status-button .btn-primary {
  border-bottom: 1px solid #e8eaf2 !important;
}

.title span {
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 50px;
}

.title span:nth-child(2) {
  color: var(--warning-badges);
}

.author {
  font-size: 14px;
  color: #6c757d;
}

.rating .dropdown {
  padding: 0 !important;
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.rating .dropdown .btn {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  transform: rotate(90deg);
}

.rating .dropdown .btn:hover {
  background-color: transparent;
}

.rating .dropdown .btn-success {
  --bs-btn-active-bg: transparent;
  padding: 0 !important;
}

.form-check-label {
  font-size: 18px;
}

.radio-container .ig-yesno-radio label {
  height: fit-content;
}

.radio-container .ig-yesno-radio:has(input[type="radio"]:checked) {
  border: none !important;
}

.nextBtn {
  width: 137px;
  height: 52px;
  margin-top: 50px;
}

.subHeader {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.copyBtn {
  background-color: transparent !important;
  border: none !important;
  color: #5b2aef !important;
}

.copyBtn:hover {
  text-decoration: underline;
}

.dropdowns {
  max-width: 320px;
}

.input-container {
  margin-left: 22px;
}

.input-container .form-label {
  display: none;
}

.input-container .custom-input,
.input-container .selectStyle .custom-select__value-container,
.input-container .selectStyle .custom-select__indicator,
.input-container .selectStyle .custom-select-with-value__indicator,
.input-container .selectStyle .custom-select-with-value__value-container {
  background-color: #eff0f6 !important;
}

.input-container .selectStyle .custom-select__control,
.input-container .selectStyle .custom-select-with-value__control {
  border: none !important;
}

.assessmentBtn {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  text-decoration: none;
  align-items: center;
}

.assessmentBtn a {
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  color: #6c757d;
  cursor: pointer;
}

.assessmentActive {
  font-weight: 500;
  color: white;
  border: 1px solid #6610f2;
  border-radius: 4px;
  background-color: #6610f2;
}

.card-count {
  color: #adb5bd;
  font-family: "Manrope";
  font-weight: 600;
  font-size: 18;
  margin-right: 20px;
}

.custom-pageChange {
  border: 1px solid #adb5bd;
  border-radius: 4px;
  height: 30px;
  width: 50px;
}

.dueDate-text {
  font-size: 12px;
}

.validtill-date {
  font-size: 12px;
  background-color: #eee;
  color: #6c757d;
  border-radius: 2px;
  margin-left: 2px !important;
}

.fees.free {
  color: #34a853 !important;
}

.fees {
  color: #f90;
  font-size: 16px;
  font-weight: 900;
}

@media (max-width: 480px) {
  .status-button .btn {
    min-width: 100px;
  }
}

@media (max-width: 350px) {
  .status-button .btn {
    min-width: 90px;
  }
}

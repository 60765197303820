.modal-body {
  margin-left: 50px;
  margin-right: 50px;
}

.modal-title {
  color: #4b4b4b;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 22px !important;
  text-align: center !important;
}

.email-heading {
  color: #1c1d1f;
  font-weight: 700;
  font-size: 16px;
}

.cancel-button {
  background-color: #ffff !important;
  color: #ee4c4c !important;
}

button.cancel-button.btn.btn-primary {
  border: 1px solid rgb(238, 76, 76) !important;
}

.availability-desc {
  font-size: 16px;
  color: #302f2f;
}

button.extend-popup.btn.btn-primary {
  background-color: #2a9cef;
  border: #2a9cef;
  border-bottom: #2a9cef !important;
}

button.email-popup.btn.btn-primary {
  border-bottom: #2a9cef !important;
  margin-left: 10px;
}

a.link-card-clr {
  color: #000000;
  text-decoration: none;
}

.user-input {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
}

.user-tag-input {
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 2px 6px;
  margin: 2px;
  border: 1px solid #cccddd;
  background: #eff0f6;
  color: #4a5568;
}

.username-input {
  flex: 1;
  border: none;
  outline: none;
  margin: 2px;
}

button.user-delete-btn {
  background: #eee;
}
.todo-gird .ag-ltr .ag-cell {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 500;
  border: none !important;
}

.todo-gird .ag-header-cell-text {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #919394;
}

.todo-gird .ag-header {
  background-color: transparent;
}

.todo-gird .ag-cell .ag-cell-wrapper .ag-selection-checkbox {
  width: 30px !important;
}

.todo-gird .ag-header-row {
  background: #eff0f6;
}

.todo-gird .ag-row-odd {
  background: #eff0f6;
}

.react-multi-email [data-tag] {
  border-radius: 2px !important;
  border: 1px solid #cccddd !important;
  background: #eff0f6 !important;
  font-size: 15px !important;
  color: #4a5568 !important;
}

.assessment-col {
  border: 1px solid #cccddd;
  background-color: white;
  border-radius: 4px;
}

.three-dots-container {
  color: gray !important;
  font-size: 40px !important;
}

.icon-container-dots {
  position: absolute;
  top: 0;
  right: 0;
}

.img-container {
  position: relative;
  background-color: none;
}

.filterTitle {
  font: 8px !important;
  font-weight: 200%;
  color: #919394;
  padding-left: 10px;
  padding-top: 2px;
}

.uinput {
  display: flex;
  width: auto;
  align-items: center;
  border: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 0x;
  margin: 3px;
  background-color: #f3f3f4;
  color: #000;
}

.timeFieldPopup {
  color: gray;
}

.dueDateButton {
  background-color: gray !important;
  border: gray !important;
}

.author span {
  font-size: 14px;
  color: #6c757d;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 25px;
}

img.mail-icon {
  height: 25px;
  width: 21px;
  color: #2196f3 !important;
  margin-right: 10px;
  margin-bottom:3px;
}

.user-table .ag-row .ag-cell {
  display: flex;
  align-items: center;
  line-height: 2;
}

.todo-input {
  margin-right: 10px;
}
.user-btn {
  margin-top: 5px;
}

.name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-container {
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: #5d5c5c;
  color: white;
  text-align: center;
  font-size: 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 67%;
  transform: translateX(-50%);
  width: 50px;
  height: 20px;
}

.tooltip-text:before {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 15%;
  margin-left: -1px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #5d5c5c transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}

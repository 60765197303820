.user-card {
  font-family: "Manrope";
  font-style: normal;
  height: 100%;
  width: 100%;
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--black);
    margin-bottom: 2px;
    text-transform: capitalize !important;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--text-secondary);
    margin: 0;
  }

  img {
    border-radius: 50%;
  }
}

.user-request-details {
  height: 100%;
  width: 100%;
  text-decoration: none;
}
.profile-info {
  align-items: flex-start !important;
  font-family: "Manrope";

  .profile-img {
    img {
      height: 88px;
      border-radius: 999px;
      @media screen and (max-width: 991px) {
        height: 80px;
      }
    }
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  h1 {
    font-size: 24px;
    font-weight: 700;
    @extend .mb-8;

    @media screen and (max-width: 991px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  p {
    @extend .mb-8;
    font-weight: 400;
    font-size: 14px;
    color: var(--text-secondary);
  }
}

.fontM {
  font-weight: 700;
  font-size: 22px;
}

.fontS {
  font-size: 12px;
}

.user-info {
  padding: 0;
  background-color: var(--background-light);
  font-family: "Manrope";

  .container-fluid {
    padding: 1.5rem;
    @media screen and (max-width: 991px) {
      padding: 1.5rem 0.6rem;
    }
  }

  .description {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--text-secondary);
  }

  .availability {
    padding-left: 0;
    list-style: none;
    max-width: 85%;

    li {
      display: flex;
      .week {
        flex: 2;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
      }

      .time {
        @extend .week;
        font-weight: 600;
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .rc {
    width: 380px !important;
  }
}

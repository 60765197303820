.form-label {
  color: #858ab4;
  font-weight: 600;
  font-size: 14px;
}

.custom-input-text,
.custom-input,
.custom-input-text--is-focused,
.custom-input--is-focused {
  border: 1px solid var(--line-gray);
  border-radius: 4px;
  box-shadow: none !important;
}

.custom-input-text {
  color: #858ab4;
  font-weight: 600;
}

.custom-input {
  outline-color: #cccddd;
  width: 100%;
  border-radius: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

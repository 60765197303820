.feedback-table .ag-row-odd {
  background: #eff0f6;
}

@media (min-width: 992px) {
  .feedback-popup .modal-lg,
  .feedback-popup .modal-xl {
    --bs-modal-width: 955px;
  }
}

.payment-button {
  border-color: #5b2aef;
  background-color: #5b2aef;
  color: #ffff;
  border-radius: 5px;
}

@media screen and (max-width: 700px){
    .year-input{
        margin-top: 10px;
    }
}

.add-card-btn {
    float: right;
}

.cancel-btn {
    background-color: #ffff !important;
    color: #5b2aef !important;
    border: #ffff !important;
}

.searchTags {
  max-width: 150px;
}

.user-table .ag-row .ag-cell {
  display: flex;
  align-items: center;
  line-height: 2;
}

.pop-up .modal-title {
  font-size: 20px;
}

.spanHeader {
  font-size: 16px;
  font-weight: normal;
  margin-right: 20px;
}

.link {
  font-size: 14px;
  font-weight: normal;
  color: #5b2aef;
}

.checkbox {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkbox .form-check-input {
  font-size: 20px;
  margin: 0;
  margin-right: 10px;
}

.checkbox .form-check-label {
  font-size: 14px;
  font-weight: bold;
}

.name {
  color: #4a0090;
  font-weight: 500;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #dddddd;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
}

.disabled-input .form-control,
.disabled-input .custom-select__control {
  opacity: 0.6;
}

.downloadBtn {
  position: relative;
  margin-left: 30rem !important;
}

input.custom-password-with-icon.custom-input.user-password.form-control {
  background-color: var(--white) !important;
  border-right: none !important;
}

span.user-password.input-group-text {
  background-color: var(--white);
  border-left: none;
}

input.custom-password-with-icon.custom-input.user-password.form-control:focus {
  border: 1px solid var(--line-gray) !important;
  box-shadow: none !important;
  border-right: none !important;
}

.reset-nepwd {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
  margin-bottom: 10px;
  margin-top: 10px;
}

.btn-user-management {
  background-color: var(--primary) !important;
}
.genie-transcript-name{
  color: #5b2aef !important;
  text-transform: capitalize;
}
.assessmentName {
  color: #4a0090;
  font-weight: 500;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
.my-requests {
  padding: 0;
  font-family: "Manrope";

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: var(--text-default);
    margin-bottom: 1rem;
    a {
      color: var(--primary-dark);
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .card {
    border-color: var(--card-border);

    @media screen and (min-width: 992px) {
      .request-card {
        .progress-container {
          width: 58%;
        }
      }
    }
  }

  .new-request {
    border-style: dashed;
    width: 100%;
    border-color: var(--card-border);
    justify-content: space-evenly;
    min-height: 100px;
    background-color: #f4f8fb;
    .btn {
      --bs-btn-padding-y: 0.5rem;
      &.btn-primary {
        font-size: 15px;
        border-width: 2px;
        font-weight: 500;
        line-height: 20px;
        border-bottom-color: var(--primary-dark);
        --bs-btn-bg: var(--primary);
        --bs-btn-border-color: var(--primary);
        --bs-btn-hover-bg: var(--primary);
        --bs-btn-hover-border-color: var(--primary);
        --bs-btn-active-bg: var(--primary);
        --bs-btn-active-border-color: var(--primary);
      }
    }
  }

  .title {
    font-size: 23px;
    font-weight: 700;
  }

  .titleSmall {
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }
}

.rrow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
}

.rrow > div {
  flex-basis: 20%;
  font-size: 24px;
  text-align: center;
}

.item1 {
  flex-grow: 1;
}

.item2 {
  flex-grow: 7;
}

.card-radio {
  display: inline-block;

  input[type="radio"] {
    display: none;

    &:not(:disabled)~label {
      cursor: pointer;
    }
  }

  label {
    padding: .4rem .8rem;
    width: 168px;
    height: 93px;
    display: block;
    background: var(--white);
    border: 1px solid var(--background-interactive-element);
    border-radius: 8px;
    position: relative;

    h3 {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: var(--text-secondary);
      margin: .2rem 0;
      max-width: 90%;
    }

    p {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 33px;
      color: var(--text-default);
    }
  }

  input[type="radio"]:checked+label {

    border: 2px solid var(--primary);

    &::after {
      content: url(../../assets/images/primary-check-circle.svg);
      height: 20px;
      width: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
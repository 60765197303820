.zego-cloud-div {
  .QAHxuJxRZWb3P_cbR8QA,
  .ji5jASszKFf2CGCmbxEh,
  .j9ygOVxEl2nClTPs77Ta {
    background-color: #ffff !important;
  }
  .lflaXazrPGAK9SbOcNoC {
    color: black;
  }
}

// .QeMJj1LEulq1ApqLHxuM,
.QYvze2FiFrLlotTk5Iz7,
.Wlqv8YhEAE9OzBkKuKjg,
.UbpDhvufmId2nxL2l5gC,
.QbarJSxzhe6iPp6VdlAs,
.aUBcrib1jsrHTK9vhlVZ,
.eLS4omBUBKIdRuH3vIbv {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
}

.QYvze2FiFrLlotTk5Iz7,
.Wlqv8YhEAE9OzBkKuKjg,
.UbpDhvufmId2nxL2l5gC,
// .QbarJSxzhe6iPp6VdlAs,
.eLS4omBUBKIdRuH3vIbv {
  // background-color: #ffff !important;
  border: 1px solid #cccddd;
}

// .vjwEXnTmP6jAK8LlvWL_ {
//   position: absolute;
//   z-index: 100;
//   bottom: 100px;
// }

.JtRS8dNV0vDLnpBoA6kZ {
  position: absolute;
  z-index: 50;
  right: 10px;
}

.UbpDhvufmId2nxL2l5gC {
  // ss
  order: 1;
}

.Wlqv8YhEAE9OzBkKuKjg {
  // video share
  order: 2;
}

.QeMJj1LEulq1ApqLHxuM {
  // vide0 end
  height: 65px !important;
  width: 65px !important;
  border-radius: 50% !important;
  order: 3;
}

.QYvze2FiFrLlotTk5Iz7 {
  // voice
  order: 4;
}

.eLS4omBUBKIdRuH3vIbv {
  // three dots
  order: 5;
}

.record-button {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: none;
  background-color: rgba(34, 34, 34, 0.3);
  color: #ffff;
  margin: 0px 10px;
}
.zego-main-dev {
  position: relative;
}
.record-btns-div {
  position: absolute;
  top: 30px;
  left: 20px;
  z-index: 100;
}

.return-text {
  color: #0055ff;
  cursor: pointer;
}

.mCx2N1NwuMWObjjTeG0q {
  display: none;
}

.return-text {
  text-decoration: none;
}

.return-div {
  background-color: #ffff;
}

@media screen and (max-width: 500px) {
  .QajuHzlZleFtiTUp_E8u .D9WLyEQaARfWqCTyVrpU{
   display: flex !important;
   flex-direction: column !important;
   height:auto !important;
   width: 350px !important;
   align-items: center !important;
   margin-right: 250px;
 }
 .notes-wrapper .notes-editor{
  width: 380px !important;
}
.BYpXSnOHfrC2td4QRijO{
  width: 380px !important;
}
// .QAHxuJxRZWb3P_cbR8QA{
//   width: 400px !important;
// }
.sCsSbKP9yxvw4LQAeaTz{
min-width: 200px !important;
}
}
@media screen and (max-width: 400px){
  .QajuHzlZleFtiTUp_E8u .D9WLyEQaARfWqCTyVrpU{
    margin-right: 310px;
  }
  .notes-wrapper .notes-editor{
    width: 330px !important;
  }
  .BYpXSnOHfrC2td4QRijO{
    width: 340px !important;
  }
  .sCsSbKP9yxvw4LQAeaTz{
    min-width: 200px !important;
    }
}
@media screen and (max-width: 350px){
  .QajuHzlZleFtiTUp_E8u .D9WLyEQaARfWqCTyVrpU{
    margin-right: 370px !important;
    width: 310px !important;
  }
  .notes-wrapper .notes-editor{
    width: 280px !important;
  }
  .BYpXSnOHfrC2td4QRijO{
    width: 310px !important;
  }
  .sCsSbKP9yxvw4LQAeaTz{
    min-width: 200px !important;
    }
}